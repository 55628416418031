import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import { PaymentFormGridContainer, Spacer } from './PaymentFormStyles';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../firebase/firebaseConfig';
import { useCart } from '../../CartContext';
import PaypalButton from '../../components/PaypalButton/PaypalButton';
import { db } from '../../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

interface PaymentFormProps {
  formValues: any;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
  submitRef: React.MutableRefObject<(() => void) | null>;
  hidden: boolean;
  setFormIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  onPaymentStatusChange: (status: string) => void;
  setPaymentError: (error: string | null) => void;
}

export default function PaymentForm({
  submitRef,
  hidden,
  setFormIsValid,
  onPaymentStatusChange,
  setPaymentError,
}: PaymentFormProps) {
  const { cart, totalAmount } = useCart(); // accessing cart from context

  const stripe = useStripe();
  const elements = useElements();
  const [cardError, setCardError] = useState<string | null>(null);

  const functions = getFunctions(app);
  const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');

  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  const handleChange = (event: { error?: { message: string } }) => {
    if (event.error) {
      setCardError(event.error.message);
      setFormIsValid(false);
    } else {
      setCardError(null);
      setFormIsValid(true);
    }
  };

  const handlePaypalSuccess = () => {
    setPaymentStatus('succeeded');
  };

  interface Product {
    id: string;
    name: string;
    description: string;
    price: number;
    colors: Color[];
    pictures: string[];
  }

  interface Color {
    color: string;
    sizes: Size[];
  }

  interface Size {
    size: string;
    stock: number;
  }

  const getProductDetails = async (id: string): Promise<Product> => {
    let product;

    try {
      const docRef = doc(db, 'products', id);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('No such product!');
      } else {
        product = docSnap.data() as Product;
      }

      return product;
    } catch (error) {
      console.error('Error getting product details:', error);
      throw error;
    }
  };

  const areItemsInStock = async () => {
    for (let cartItem of cart) {
      const product = await getProductDetails(cartItem.id);
      console.log(
        `Product ID: ${cartItem.id}, Quantity in Cart: ${cartItem.quantity}`
      ); // log product id and quantity in cart

      const selectedColorObject = product.colors.find(
        (colorObject: Color) => colorObject.color === cartItem.color
      );
      const selectedSizeObject = selectedColorObject?.sizes.find(
        (sizeObject: Size) => sizeObject.size === cartItem.size
      );
      const selectedStock = selectedSizeObject?.stock || 0;

      console.log(`Selected Stock: ${selectedStock}`); // log the selected stock

      if (selectedStock - cartItem.quantity < 0) {
        console.log(`Not enough stock for Product ID: ${cartItem.id}`); // log if there's not enough stock
        return false;
      }
    }
    console.log('All items in stock'); // log if all items are in stock
    return true;
  };

  const processPayment = async (paymentMethod: PaymentMethod | null) => {
    if (!paymentMethod) {
      throw new Error('Payment method is missing');
    }

    // Check if all items in the cart are still available
    const itemsInStock = await areItemsInStock();
    if (!itemsInStock) {
      throw new Error('Some items in your cart are no longer available');
    }

    try {
      const { data } = (await createPaymentIntent({
        paymentMethodId: paymentMethod.id,
        amount: totalAmount * 100, // Stripe uses amounts in cents
        cartItems: cart, // pass the cart items
      })) as { data: { clientSecret: string } };

      return data.clientSecret;
    } catch (error) {
      console.error('Error processing payment:', error);
      throw error;
    }
  };

  const handlePayment = async (): Promise<void> => {
    try {
      if (!stripe || !elements) {
        throw new Error('Stripe has not been initialized');
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error('Card Element not found');
      }

      // Check if all items in the cart are still available
      const itemsInStock = await areItemsInStock();
      if (!itemsInStock) {
        throw new Error('Some items in your cart are no longer available');
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setPaymentError(error.message || 'An error occurred');
        throw error;
      } else {
        setPaymentError(null);

        const clientSecret = await processPayment(paymentMethod);

        if (!clientSecret) {
          throw new Error('Failed to create payment intent');
        }

        const { error: confirmError, paymentIntent } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod?.id,
          });

        if (confirmError) {
          console.error('Confirmation error:', confirmError);
          setPaymentError(confirmError.message || 'An error occurred'); // directly use the confirmError message
          throw confirmError;
        }

        if (paymentIntent) {
          setPaymentStatus(paymentIntent.status);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Payment error:', error);
        setPaymentError(error.message);
      } else {
        setPaymentError('An unknown error occurred');
      }
    }
  };

  useEffect(() => {
    submitRef.current = handlePayment;
    console.log(`Ref: ${submitRef.current}`); // log the selected stock
  }, [handlePayment, submitRef]);

  useEffect(() => {
    if (paymentStatus) {
      onPaymentStatusChange(paymentStatus);
    }
  }, [paymentStatus, onPaymentStatusChange]);

  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <Typography variant="h4" gutterBottom>
        Order Total: {totalAmount.toFixed(2)} € - Shipping is on us
      </Typography>
      {/*<Typography variant="h5" gutterBottom>*/}
      {/*  PayPal*/}
      {/*</Typography>*/}
      <PaypalButton
        amount={totalAmount.toString()}
        onSuccess={handlePaypalSuccess}
      />
      <Spacer />
      {/*<form onSubmit={e => e.preventDefault()}>*/}
      {/*  <Typography variant="h5" gutterBottom>*/}
      {/*    Card */}
      {/*  </Typography>*/}
      {/*  <PaymentFormGridContainer container spacing={3}>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <CardElement*/}
      {/*        onChange={handleChange}*/}
      {/*        options={{*/}
      {/*          hidePostalCode: true,*/}
      {/*          style: {*/}
      {/*            base: {*/}
      {/*              fontSize: '16px',*/}
      {/*              color: '#424770',*/}
      {/*              '::placeholder': { color: '#aab7c4' },*/}
      {/*            },*/}
      {/*          },*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <Typography color="error">{cardError}</Typography>*/}
      {/*    </Grid>*/}
      {/*  </PaymentFormGridContainer>*/}
      {/*</form>*/}
    </div>
  );
}
