import React, { useEffect, useState } from 'react';
import FullScreenVideo from '../../components/FullScreenVideo/FullScreenVideo';
import {
  HomepageContainer,
  PageLink,
  PageLinkImage,
  PageLinksContainer,
  TextOverlay,
} from './HomePageStyles';

import { storage } from '../../firebase/firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import SpaceCollection from '../CurrentCollection/SpaceCollection';
import Typography from '@mui/material/Typography';

const HomePage: React.FC = () => {
  const [lookbookImage, setLookbookImage] = useState('');
  const [spaceCollectionImage, setSpaceCollectionImage] = useState('');

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const lookbookRef = ref(storage, 'Home Page/lookbook.jpg');
        const spaceCollectionRef = ref(
          storage,
          'Home Page/space_collection.jpg'
        );

        const lookbookUrl = await getDownloadURL(lookbookRef);
        const spaceCollectionUrl = await getDownloadURL(spaceCollectionRef);

        setLookbookImage(lookbookUrl);
        setSpaceCollectionImage(spaceCollectionUrl);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <HomepageContainer>
      <FullScreenVideo
        srcWebm="/videos/introRF36.webm"
        srcMp4="/videos/introRF26.mp4"
      />

      <SpaceCollection />

      {/*<PageLinksContainer>*/}
      {/*  <PageLink href="/space-collection">*/}
      {/*    <PageLinkImage src={spaceCollectionImage} alt="Space Collection" />*/}
      {/*    <TextOverlay>*/}
      {/*      <Typography variant="h4">Interstellar Collection</Typography>*/}
      {/*    </TextOverlay>*/}
      {/*  </PageLink>*/}
      {/*  <PageLink href="/lookbook">*/}
      {/*    <PageLinkImage src={lookbookImage} alt="LookBook" />*/}
      {/*    <TextOverlay>*/}
      {/*      <Typography variant="h4">LookBook</Typography>*/}
      {/*    </TextOverlay>*/}
      {/*  </PageLink>*/}
      {/*</PageLinksContainer>*/}
    </HomepageContainer>
  );
};

export default HomePage;
