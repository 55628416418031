import React, { createContext, useContext, useState, useEffect } from 'react';

export interface CartItem {
  // Exporting CartItem here
  id: string;
  name: string;
  description1: string;
  description2: string;
  description3: string;
  price: number;
  quantity: number;
  color: string;
  size: string;
  image: string;
}

interface CartContextProps {
  cart: CartItem[];
  totalAmount: number;
  addToCart: (item: CartItem) => void;
  removeFromCart: (itemId: string) => void;
  clearCart: () => void;
}

interface CartProviderProps {
  children: React.ReactNode;
}

const CartContext = createContext<CartContextProps>({
  cart: [],
  totalAmount: 0,
  addToCart: () => {},
  removeFromCart: () => {},
  clearCart: () => {},
});

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const [totalAmount, setTotalAmount] = useState<number>(0);

  // Save cart to localStorage and update total amount whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));

    // Update total amount
    let amount = 0;
    for (let item of cart) {
      amount += item.price * item.quantity;
    }
    setTotalAmount(amount);
  }, [cart]);

  const addToCart = (item: CartItem) => {
    setCart(prevCart => {
      const existingItemIndex = prevCart.findIndex(
        i => i.id === item.id && i.color === item.color && i.size === item.size
      );

      if (existingItemIndex !== -1) {
        const newCart = [...prevCart];
        newCart[existingItemIndex] = {
          ...newCart[existingItemIndex],
          quantity: newCart[existingItemIndex].quantity + 1,
        };
        return newCart;
      }

      return [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const removeFromCart = (itemId: string) => {
    setCart(prevCart => prevCart.filter(item => item.id !== itemId));
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{ cart, totalAmount, addToCart, removeFromCart, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};
