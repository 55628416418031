import React from 'react';
import { Typography, Container } from '@mui/material';

const LegalNotice: React.FC = () => {
  return (
    <Container>
      <Typography variant="body1">
        <Typography variant="h3" gutterBottom>
          Impressum (Legal Notice)
        </Typography>
        <Typography variant="body1">
          Vinzenz Fitz und Kilian Ludwig GbR
        </Typography>
        <Typography variant="body1">Gewerbering 7</Typography>
        <Typography variant="body1">83370 Seeon</Typography>
        <Typography variant="body1">Deutschland</Typography>
        <Typography variant="body1">Tel.: +49 175 8685425</Typography>
        <Typography variant="body1">E-Mail: info@paraline.de</Typography>
        <Typography variant="body1">
          Vertretungsberechtigte Gesellschafter/innen: Vinzenz Max Fitz, Kilian
          Ludwig
        </Typography>
        <Typography variant="body1">Steuernummer: 163/159/15302</Typography>
        <Typography variant="body1">
          Plattform der EU-Kommission zur Online-Streitbeilegung:{' '}
          <a
            href="https://ec.europa.eu/odr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/odr
          </a>
        </Typography>
        <Typography variant="body1">
          Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
        </Typography>
      </Typography>
    </Container>
  );
};

export default LegalNotice;
