import { styled } from '@mui/system';
import { Grid, CardMedia, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.primary,
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: theme.spacing(4),
}));

export const ProductsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  maxWidth: '1200px',
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

export const CardWrapper = styled(Grid)(({ theme }) => ({
  height: '100%',
  maxWidth: '300px', // max-width of a card, you can adjust as needed
  margin: theme.spacing(2),
}));

export const CardImageArea = styled(CardMedia)(({ theme }) => ({
  aspectRatio: '16/9',
  paddingTop: '56.25%', // 16:9
}));

export const ProductCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ProductTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

export const ProductPrice = styled(Typography)(({ theme }) => ({}));
