import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

export const FooterContainer = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  boxSizing: 'border-box', // include padding and border in element's total width and height

  backgroundColor: theme.palette.background.dark,
  color: theme.palette.text.light,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const LinkContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(6),
  marginRight: theme.spacing(6),
  display: 'flex',
}));

export const IconContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}));

export const FooterLink = styled(Link)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  color: theme.palette.text.light,
  textDecoration: 'none',
}));

export const SocialMediaIcon = styled('a')(({ theme }) => ({
  color: '#f5f5f5',
  marginLeft: theme.spacing(2),
  transition: 'transform 0.2s ease-in-out', // smooth transition for hover effect
}));
