import React from 'react';
import { ButtonProps } from '@mui/material';
import MainButtonStyles from './MainButtonStyles';

interface MainButtonProps extends ButtonProps {
  buttonVariant?: 'primary' | 'secondary';
  buttonSize?: 'small' | 'medium' | 'large';
  href?: string;
}

const MainButton: React.FC<MainButtonProps> = ({
  buttonVariant = 'primary',
  buttonSize = 'medium',
  href,
  ...props
}) => {
  const variant = buttonVariant === 'primary' ? 'outlined' : 'text';
  const size = buttonSize;

  return (
    <MainButtonStyles variant={variant} size={size} href={href} {...props} />
  );
};

export default MainButton;
