import { styled } from '@mui/system';
import { Grid, Checkbox } from '@mui/material';

export const AddressFormGridContainer = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const AddressFormCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;
