import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, useMediaQuery, useTheme } from '@mui/material';
import fetchProductInfo from '../../firebase/fetchProductInfo';
import {
  Container,
  ProductsContainer,
  CardWrapper,
  ProductCardContent,
  ProductTitle,
  ProductPrice,
} from './SpaceCollectionStyles';

const SpaceCollection: React.FC = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [hoverImages, setHoverImages] = useState<Record<string, string | null>>(
    {}
  );
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      const productInfo = await fetchProductInfo('products');
      setProducts(productInfo);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleProductClick = (product: any) => {
    navigate(`/space-collection/${product.id}`, { state: { product } });
  };

  return (
    <Container>
      <Typography variant="h1">Interstellar Collection</Typography>
      {loading ? (
        <Typography variant="body1">Loading...</Typography>
      ) : (
        <ProductsContainer>
          {products.map(product => (
            <CardWrapper key={product.id} item xs={12} sm={6} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardActionArea
                  onClick={() => handleProductClick(product)}
                  onMouseEnter={() =>
                    setHoverImages(prev => ({
                      ...prev,
                      [product.id]: product.imageUrls[2],
                    }))
                  }
                  onMouseLeave={() =>
                    setHoverImages(prev => ({ ...prev, [product.id]: null }))
                  }
                >
                  <CardMedia
                    component="img"
                    image={hoverImages[product.id] || product.imageUrls[0]}
                    alt={product.name}
                    onError={() =>
                      console.error(
                        'Error loading image:',
                        product.imageUrls[0]
                      )
                    }
                  />
                  <ProductCardContent>
                    <ProductTitle variant="h4">{product.name}</ProductTitle>
                    <ProductPrice variant="h5">{product.price} €</ProductPrice>
                  </ProductCardContent>
                </CardActionArea>
              </Card>
            </CardWrapper>
          ))}
        </ProductsContainer>
      )}
    </Container>
  );
};

export default SpaceCollection;
