import React from 'react';
import { Typography, Container } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <Typography variant="body1">
        <header>
          <h1>
            <span>Datenschutzerklärung (Privacy Policy)</span>
          </h1>
          <p></p>
        </header>
        <div>
          <h2>
            <span>1) Einleitung und Kontaktdaten des Verantwortlichen</span>
          </h2>
          <p>
            <strong>
              <span>1.1</span>
            </strong>
            <span>
               Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns
              für Ihr Interesse. Im Folgenden informieren wir Sie über den
              Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer
              Website. Personenbezogene Daten sind hierbei alle Daten, mit denen
              Sie persönlich identifiziert werden können.
            </span>
          </p>
          <p>
            <strong>
              <span>1.2</span>
            </strong>
            <span>
               Verantwortlicher für die Datenverarbeitung auf dieser Website im
              Sinne der Datenschutz-Grundverordnung (DSGVO) ist Vinzenz Fitz und
              Kilian Ludwig GbR, Gewerbering 7, 83370 Seeon, Deutschland, Tel.:
              +49 175 8685425, E-Mail: paraline.label@gmail.com. Der für die
              Verarbeitung von personenbezogenen Daten Verantwortliche ist
              diejenige natürliche oder juristische Person, die allein oder
              gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
              von personenbezogenen Daten entscheidet.
            </span>
          </p>
          <p>
            <strong>
              <span>1.3</span>
            </strong>
            <span>
               Diese Website nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung personenbezogener Daten und anderer vertraulicher
              Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen)
              eine SSL-bzw. TLS-Verschlüsselung. Sie können eine verschlüsselte
              Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol
              in Ihrer Browserzeile erkennen.
            </span>
          </p>
          <h2>
            <span>2) Datenerfassung beim Besuch unserer Website</span>
          </h2>
          <p>
            <span>
              Bei der bloß informatorischen Nutzung unserer Website, also wenn
              Sie sich nicht registrieren oder uns anderweitig Informationen
              übermitteln, erheben wir nur solche Daten, die Ihr Browser an den
              Seitenserver übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere
              Website aufrufen, erheben wir die folgenden Daten, die für uns
              technisch erforderlich sind, um Ihnen die Website anzuzeigen:
            </span>
          </p>
          <ul>
            <li>
              <span>Unsere besuchte Website</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Menge der gesendeten Daten in Byte</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                Quelle/Verweis, von welchem Sie auf die Seite gelangten
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Verwendeter Browser</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Verwendetes Betriebssystem</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</span>
            </li>
          </ul>
          <p>
            <span>
              Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf
              Basis unseres berechtigten Interesses an der Verbesserung der
              Stabilität und Funktionalität unserer Website. Eine Weitergabe
              oder anderweitige Verwendung der Daten findet nicht statt. Wir
              behalten uns allerdings vor, die Server-Logfiles nachträglich zu
              überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige
              Nutzung hinweisen.
            </span>
          </p>
          <h2>
            <span>3) Cookies</span>
          </h2>
          <p>
            <span>
              Um den Besuch unserer Website attraktiv zu gestalten und die
              Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir
              Cookies, also kleine Textdateien, die auf Ihrem Endgerät abgelegt
              werden. Teilweise werden diese Cookies nach Schließen des Browsers
              automatisch wieder gelöscht (sog. „Session-Cookies“), teilweise
              verbleiben diese Cookies länger auf Ihrem Endgerät und ermöglichen
              das Speichern von Seiteneinstellungen (sog. „persistente
              Cookies“). Im letzteren Fall können Sie die Speicherdauer der
              Übersicht zu den Cookie-Einstellungen Ihres Webbrowsers entnehmen.
            </span>
          </p>
          <p>
            <span>
              Sofern durch einzelne von uns eingesetzte Cookies auch
              personenbezogene Daten verarbeitet werden, erfolgt die
              Verarbeitung gemäß Art. 6 Abs. 1 lit. b DSGVO entweder zur
              Durchführung des Vertrages, gemäß Art. 6 Abs. 1 lit. a DSGVO im
              Falle einer erteilten Einwilligung oder gemäß Art. 6 Abs. 1 lit. f
              DSGVO zur Wahrung unserer berechtigten Interessen an der
              bestmöglichen Funktionalität der Website sowie einer
              kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
            </span>
          </p>
          <p>
            <span>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und einzeln über deren Annahme
              entscheiden oder die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen können.
            </span>
          </p>
          <p>
            <span>
              Bitte beachten Sie, dass bei Nichtannahme von Cookies die
              Funktionalität unserer Website eingeschränkt sein kann.
            </span>
          </p>
          <h2>
            <span>4) Kontaktaufnahme</span>
          </h2>
          <p>
            <span>
              Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular
              oder E-Mail) werden – ausschließlich zum Zweck der Bearbeitung und
              Beantwortung Ihres Anliegens und nur im dafür erforderlichen
              Umfang – personenbezogene Daten verarbeitet.
            </span>
          </p>
          <p>
            <span>
              Rechtsgrundlage für die Verarbeitung dieser Daten ist unser
              berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß
              Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf einen
              Vertrag ab, so ist zusätzliche Rechtsgrundlage für die
              Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden
              gelöscht, wenn sich aus den Umständen entnehmen lässt, dass der
              betroffene Sachverhalt abschließend geklärt ist und sofern keine
              gesetzlichen Aufbewahrungspflichten entgegenstehen.
            </span>
          </p>
          <h2>
            <span>5) Datenverarbeitung bei Eröffnung eines Kundenkontos</span>
          </h2>
          <p>
            <span>
              Gemäß Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene Daten im
              jeweils erforderlichen Umfang weiterhin erhoben und verarbeitet,
              wenn Sie uns diese bei der Eröffnung eines Kundenkontos mitteilen.
              Welche Daten für die Kontoeröffnung erforderlich sind, entnehmen
              Sie der Eingabemaske des entsprechenden Formulars auf unserer
              Website.
            </span>
          </p>
          <p>
            <span>
              Eine Löschung Ihres Kundenkontos ist jederzeit möglich und kann
              durch eine Nachricht an die o.g. Adresse des Verantwortlichen
              erfolgen. Nach Löschung Ihres Kundenkontos werden Ihre Daten
              gelöscht, sofern alle darüber geschlossenen Verträge vollständig
              abgewickelt sind, keine gesetzlichen Aufbewahrungsfristen
              entgegenstehen und unsererseits kein berechtigtes Interesse an der
              Weiterspeicherung fortbesteht.
            </span>
          </p>
          <h2>
            <span>6) Nutzung von Kundendaten zur Direktwerbung</span>
          </h2>
          <p>
            <span>Anmeldung zu unserem E-Mail-Newsletter</span>
          </p>
          <p>
            <span>
              Wenn Sie sich zu unserem E-Mail Newsletter anmelden, übersenden
              wir Ihnen regelmäßig Informationen zu unseren Angeboten.
              Pflichtangabe für die Übersendung des Newsletters ist allein Ihre
              E-Mailadresse. Die Angabe weiterer Daten ist freiwillig und wird
              verwendet, um Sie persönlich ansprechen zu können. Für den
              Newsletter-Versand verwenden wir das sog. Double Opt-in Verfahren,
              mit dem sichergestellt wird, dass Sie Newsletter erst erhalten,
              wenn Sie uns durch Betätigung eines an die angegebene Mailadresse
              versandten Verifizierungslinks ausdrücklich Ihre Einwilligung in
              den Newsletterempfang bestätigt haben
            </span>
          </p>
          <p>
            <span>
              Mit der Aktivierung des Bestätigungslinks erteilen Sie uns Ihre
              Einwilligung für die Nutzung Ihrer personenbezogenen Daten gemäß
              Art. 6 Abs. 1 lit. a DSGVO. Hierbei speichern wir Ihre vom
              Internet Service-Provider (ISP) eingetragene IP-Adresse sowie das
              Datum und die Uhrzeit der Anmeldung, um einen möglichen Missbrauch
              Ihrer E-Mail- Adresse zu einem späteren Zeitpunkt nachvollziehen
              zu können. Die von uns bei der Anmeldung zum Newsletter erhobenen
              Daten werden streng zweckgebunden verwendet.
            </span>
          </p>
          <p>
            <span>
              Sie können den Newsletter jederzeit über den dafür vorgesehenen
              Link im Newsletter oder durch entsprechende Nachricht an den
              eingangs genannten Verantwortlichen abbestellen. Nach erfolgter
              Abmeldung wird Ihre E-Mailadresse unverzüglich in unserem
              Newsletter-Verteiler gelöscht, soweit Sie nicht ausdrücklich in
              eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns
              eine darüberhinausgehende Datenverwendung vorbehalten, die
              gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung
              informieren.
            </span>
          </p>
          <h2>
            <span>7) Datenverarbeitung zur Bestellabwicklung</span>
          </h2>
          <p>
            <strong>
              <span>7.1</span>
            </strong>
            <span>
               Soweit für die Vertragsabwicklung zu Liefer- und Zahlungszwecken
              erforderlich, werden die von uns erhobenen personenbezogenen Daten
              gemäß Art. 6 Abs. 1 lit. b DSGVO an das beauftragte
              Transportunternehmen und das beauftragte Kreditinstitut
              weitergegeben.
            </span>
          </p>
          <p>
            <span>
              Sofern wir Ihnen auf Grundlage eines entsprechenden Vertrages
              Aktualisierungen für Waren mit digitalen Elementen oder für
              digitale Produkte schulden, verarbeiten wir die von Ihnen bei der
              Bestellung übermittelten Kontaktdaten (Name, Anschrift,
              Mailadresse), um Sie im Rahmen unserer gesetzlichen
              Informationspflichten gemäß Art. 6 Abs. 1 lit. c DSGVO auf
              geeignetem Kommunikationsweg (etwa postalisch oder per Mail) über
              anstehende Aktualisierungen im gesetzlich vorgesehenen Zeitraum
              persönlich zu informieren. Ihre Kontaktdaten werden hierbei streng
              zweckgebunden für Mitteilungen über von uns geschuldete
              Aktualisierungen verwendet und zu diesem Zweck durch uns nur
              insoweit verarbeitet, wie dies für die jeweilige Information
              erforderlich ist.
            </span>
          </p>
          <p>
            <span>
              Zur Abwicklung Ihrer Bestellung arbeiten wir ferner mit dem / den
              nachstehenden Dienstleister(n) zusammen, die uns ganz oder
              teilweise bei der Durchführung geschlossener Verträge
              unterstützen. An diese Dienstleister werden nach Maßgabe der
              folgenden Informationen gewisse personenbezogene Daten
              übermittelt.
            </span>
          </p>
          <p>
            <strong>
              <span>7.3</span>
            </strong>
            <span>
               Weitergabe personenbezogener Daten an Versanddienstleister
            </span>
          </p>
          <ul>
            <li>
              <span>DHL</span>
            </li>
          </ul>
          <p>
            <span>
              Als Transportdienstleister nutzen wir den nachstehenden Anbieter:
              DHL Paket GmbH, Sträßchensweg 10, 53113 Bonn, Deutschland
            </span>
          </p>
          <p>
            <span>
              Wir geben Ihre E-Mail-Adresse und/oder Telefonnummer gemäß Art. 6
              Abs. 1 lit. a DSGVO vor der Zustellung der Ware zum Zweck der
              Abstimmung eines Liefertermins bzw. zur Lieferankündigung an den
              Anbieter weiter, sofern Sie hierfür im Bestellprozess Ihre
              ausdrückliche Einwilligung erteilt haben. Anderenfalls geben wir
              zum Zwecke der Zustellung gemäß Art. 6 Abs. 1 lit. b DSGVO nur den
              Namen des Empfängers und die Lieferadresse an den Anbieter weiter.
              Die Weitergabe erfolgt nur, soweit dies für die Warenlieferung
              erforderlich ist. In diesem Fall ist eine vorherige Abstimmung des
              Liefertermins mit dem Anbieter bzw. die Lieferankündigung nicht
              möglich.
            </span>
          </p>
          <p>
            <span>
              Die Einwilligung kann jederzeit mit Wirkung für die Zukunft
              gegenüber dem oben bezeichneten Verantwortlichen oder gegenüber
              dem Anbieter widerrufen werden.
            </span>
          </p>
          <p>
            <strong>
              <span>7.4</span>
            </strong>
            <span>
               Verwendung von Paymentdienstleistern (Zahlungsdiensten)
            </span>
          </p>
          <ul>
            <li>
              <span>Apple Pay</span>
            </li>
          </ul>
          <p>
            <span>
              Wenn Sie sich für die Zahlungsart „Apple Pay“ der Apple
              Distribution International (Apple), Hollyhill Industrial Estate,
              Hollyhill, Cork, Irland, entscheiden, erfolgt die
              Zahlungsabwicklung über die „Apple Pay“-Funktion Ihres mit iOS,
              watchOS oder macOS betriebenen Endgerätes durch die Belastung
              einer bei „Apple Pay“ hinterlegten Zahlungskarte. Apple Pay
              verwendet hierbei Sicherheitsfunktionen, die in die Hardware und
              Software Ihres Geräts integriert sind, um Ihre Transaktionen zu
              schützen. Für die Freigabe einer Zahlung ist somit die Eingabe
              eines zuvor durch Sie festgelegten Codes sowie die Verifizierung
              mittels der „Face ID“- oder „Touch ID“ – Funktion ihres Endgerätes
              erforderlich.
            </span>
          </p>
          <p>
            <span>
              Zum Zwecke der Zahlungsabwicklung werden Ihre im Rahmen des
              Bestellvorgangs mitgeteilten Informationen nebst den Informationen
              über Ihre Bestellung in verschlüsselter Form an Apple
              weitergegeben. Apple verschlüsselt diese Daten sodann erneut mit
              einem entwicklerspezifischen Schlüssel, bevor die Daten zur
              Durchführung der Zahlung an den Zahlungsdienstleister der in Apple
              Pay hinterlegten Zahlungskarte übermittelt werden. Die
              Verschlüsselung sorgt dafür, dass nur die Website, über die der
              Einkauf getätigt wurde, auf die Zahlungsdaten zugreifen kann.
              Nachdem die Zahlung getätigt wurde, sendet Apple zur Bestätigung
              des Zahlungserfolges Ihre Geräteaccountnummer sowie einen
              transaktionsspezifischen, dynamischen Sicherheitscode an die
              Ausgangswebsite.
            </span>
          </p>
          <p>
            <span>
              Sofern bei den beschriebenen Übermittlungen personenbezogene Daten
              verarbeitet werden, erfolgt die Verarbeitung ausschließlich zum
              Zwecke der Zahlungsabwicklung gemäß Art. 6 Abs. 1 lit. b DSGVO.
            </span>
          </p>
          <p>
            <span>
              Apple bewahrt anonymisierte Transaktionsdaten auf, darunter der
              ungefähre Kaufbetrag, das ungefähre Datum und die ungefähre
              Uhrzeit sowie die Angabe, ob die Transaktion erfolgreich
              abgeschlossen wurde. Durch die Anonymisierung wird ein
              Personenbezug vollständig ausgeschlossen. Apple nutzt die
              anonymisierten Daten zur Verbesserung von „Apple Pay“ und anderen
              Apple-Produkten und Diensten.
            </span>
          </p>
          <p>
            <span>
              Wenn Sie Apple Pay auf dem iPhone oder der Apple Watch zum
              Abschluss eines Kaufs verwenden, den Sie über Safari auf dem Mac
              getätigt haben, kommunizieren der Mac und das Autorisierungsgerät
              über einen verschlüsselten Kanal auf den Apple-Servern. Apple
              verarbeitet oder speichert keine dieser Informationen in einem
              Format, mit dem Ihre Person identifiziert werden kann. Sie können
              die Möglichkeit zur Verwendung von Apple Pay auf Ihrem Mac in den
              Einstellungen Ihres iPhone deaktivieren. Gehen Sie zu "Wallet
              &amp; Apple Pay", und deaktivieren Sie "Zahlungen auf Mac
              erlauben".
            </span>
          </p>
          <p>
            <span>
              Weitere Hinweise zum Datenschutz bei Apple Pay finden Sie unter
              der nachstehenden Internetadresse: 
            </span>
            <a href="https://support.apple.com/de-de/HT203027">
              <span>https://support.apple.com/de-de/HT203027</span>
            </a>
          </p>
          <ul>
            <li>
              <span>Google Pay</span>
            </li>
          </ul>
          <p>
            <span>
              Wenn Sie sich für die Zahlungsart „Google Pay“ der Google Ireland
              Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland
              („Google“) entscheiden, erfolgt die Zahlungsabwicklung über die
              „Google Pay“-Applikation Ihres mit mindestens Android 4.4
              („KitKat“) betriebenen und über eine NFC-Funktion verfügenden
              mobilen Endgeräts durch die Belastung einer bei Google Pay
              hinterlegten Zahlungskarte oder einem dort verifizierten
              Bezahlsystem (z.B. PayPal). Für die Freigabe einer Zahlung über
              Google Pay in Höhe von mehr als 25,- € ist das vorherige
              Entsperren Ihres mobilen Endgerätes durch die jeweils
              eingerichtete Verifikationsmaßnahme (etwa Gesichtserkennung,
              Passwort, Fingerabdruck oder Muster) erforderlich.
            </span>
          </p>
          <p>
            <span>
              Zum Zwecke der Zahlungsabwicklung werden Ihre im Rahmen des
              Bestellvorgangs mitgeteilten Informationen nebst den Informationen
              über Ihre Bestellung an Google weitergegeben. Google übermittelt
              sodann Ihre in Google Pay hinterlegten Zahlungsinformationen in
              Form einer einmalig vergebenen Transaktionsnummer an die
              Ausgangswebsite, mit der eine erfolgte Zahlung verifiziert wird.
              Diese Transaktionsnummer enthält keinerlei Informationen zu den
              realen Zahldaten Ihrer bei Google Pay hinterlegten Zahlungsmittel,
              sondern wird als einmalig gültiger numerischer Token erstellt und
              übermittelt. Bei sämtlichen Transaktionen über Google Pay tritt
              Google lediglich als Vermittler zur Abwicklung des Bezahlvorgangs
              auf. Die Durchführung der Transaktion erfolgt ausschließlich im
              Verhältnis zwischen dem Nutzer und der Ausgangswebsite durch
              Belastung des bei Google Pay hinterlegten Zahlungsmittels.
            </span>
          </p>
          <p>
            <span>
              Sofern bei den beschriebenen Übermittlungen personenbezogene Daten
              verarbeitet werden, erfolgt die Verarbeitung ausschließlich zum
              Zwecke der Zahlungsabwicklung gemäß Art. 6 Abs. 1 lit. b DSGVO.
            </span>
          </p>
          <p>
            <span>
              Google behält sich vor, bei jeder über Google Pay getätigten
              Transaktion bestimmte vorgangsspezifische Informationen zu
              erheben, zu speichern und auszuwerten. Hierzu gehören Datum,
              Uhrzeit und Betrag der Transaktion, Händlerstandort und
              -beschreibung, eine vom Händler bereitgestellte Beschreibung der
              gekauften Waren oder Dienste, Fotos, die Sie der Transaktion
              beigefügt haben, der Name und die E-Mail-Adresse des Verkäufers
              und Käufers bzw. des Absenders und Empfängers, die verwendete
              Zahlungsmethode, Ihre Beschreibung für den Grund der Transaktion
              sowie gegebenenfalls das mit der Transaktion verbundene Angebot.
            </span>
          </p>
          <p>
            <span>
              Laut Google erfolgt diese Verarbeitung ausschließlich gemäß Art. 6
              Abs.1 lit. f DSGVO auf Basis des berechtigten Interesses an der
              ordnungsgemäßen Rechnungslegung, der Verifizierung von
              Vorgangsdaten und der Optimierung und Funktionserhaltung des
              Google Pay-Dienstes.
            </span>
          </p>
          <p>
            <span>
              Google behält sich außerdem vor, die verarbeiteten Vorgangsdaten
              mit weiteren Informationen zusammenzuführen, die bei der Nutzung
              weiterer Google-Dienste durch Google erhoben und gespeichert
              werden.
            </span>
          </p>
          <p>
            <span>
              Die Nutzungsbedingungen von Google Pay finden sich hier:
            </span>
          </p>
          <p>
            <a href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&amp;ldt=googlepaytos&amp;ldl=de">
              <span>
                https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&amp;ldt=googlepaytos&amp;ldl=de
              </span>
            </a>
          </p>
          <p>
            <span>
              Weitere Hinweise zum Datenschutz bei Google Pay finden Sie unter
              der nachstehenden Internetadresse:
            </span>
          </p>
          <p>
            <a href="https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&amp;ldt=privacynotice&amp;ldl=de">
              <span>
                https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&amp;ldt=privacynotice&amp;ldl=de
              </span>
            </a>
          </p>
          <ul>
            <li>
              <span>Klarna</span>
            </li>
          </ul>
          <p>
            <span>
              Auf dieser Website stehen eine oder mehrere Online-Zahlungsarten
              des folgenden Anbieters zur Verfügung: Klarna Bank AB, Sveavägen
              46, 111 34 Stockholm, Schweden
            </span>
          </p>
          <p>
            <span>
              Bei Auswahl einer Zahlungsart des Anbieters, bei der Sie in
              Vorleistung gehen (etwa Kreditkartenzahlung), werden an diesen
              Ihre im Rahmen des Bestellvorgangs mitgeteilten Zahlungsdaten
              (darunter Name, Anschrift, Bank- und Zahlkarteninformationen,
              Währung und Transaktionsnummer) sowie Informationen über den
              Inhalt Ihrer Bestellung gemäß Art. 6 Abs. 1 lit. b DSGVO
              weitergegeben. Die Weitergabe Ihrer Daten erfolgt in diesem Falle
              ausschließlich zum Zweck der Zahlungsabwicklung mit dem Anbieter
              und nur insoweit, als sie hierfür erforderlich ist.
            </span>
          </p>
          <p>
            <span>
              Bei Auswahl einer Zahlungsart, bei der der Anbieter in Vorleistung
              geht (etwa Rechnungs- oder Ratenkauf bzw. Lastschrift), werden Sie
              im Bestellablauf auch aufgefordert, bestimmte persönliche Daten
              (Vor- und Nachname, Straße, Hausnummer, Postleitzahl, Ort,
              Geburtsdatum, E-Mail-Adresse, Telefonnummer, ggf. Daten zu einem
              alternativen Zahlungsmittel) anzugeben.
            </span>
          </p>
          <p>
            <span>
              Um unser berechtigtes Interesse an der Feststellung der
              Zahlungsfähigkeit unserer Kunden zu wahren, werden diese Daten von
              uns gemäß Art. 6 Abs. 1 lit. f DSGVO zum Zweck einer
              Bonitätsprüfung an den Anbieter weitergeleitet. Der Anbieter prüft
              auf Basis der von Ihnen angegebenen persönlichen Daten sowie
              weiterer Daten (wie etwa Warenkorb, Rechnungsbetrag,
              Bestellhistorie, Zahlungserfahrungen), ob die von Ihnen
              ausgewählte Zahlungsmöglichkeit im Hinblick auf Zahlungs- und/oder
              Forderungsausfallrisiken gewährt werden kann.
            </span>
          </p>
          <p>
            <span>
              Zur Entscheidung im Rahmen der Antragsprüfung können neben
              anbieterinternen Kriterien gemäß Art. 6 Abs. 1 lit. f DSGVO auch
              Identitäts- und Bonitätsinformationen von folgenden Auskunfteien
              einbezogen werden:
            </span>
          </p>
          <p>
            <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/de_de/credit_rating_agencies">
              <span>
                https://cdn.klarna.com/1.0/shared/content/legal/terms/0/de_de/credit_rating_agencies
              </span>
            </a>
          </p>
          <p>
            <span>
              Die Bonitätsauskunft kann Wahrscheinlichkeitswerte enthalten (sog.
              Score-Werte). Soweit Score-Werte in das Ergebnis der
              Bonitätsauskunft einfließen, haben sie ihre Grundlage in einem
              wissenschaftlich anerkannten mathematisch-statistischen Verfahren.
              In die Berechnung der Score-Werte fließen unter anderem, aber
              nicht ausschließlich, Anschriftendaten ein.
            </span>
          </p>
          <p>
            <span>
              Sie können dieser Verarbeitung Ihrer Daten jederzeit durch eine
              Nachricht an uns oder gegenüber dem Anbieter widersprechen. Jedoch
              bleibt der Anbieter ggf. weiterhin berechtigt, Ihre
              personenbezogenen Daten zu verarbeiten, sofern dies zur
              vertragsgemäßen Zahlungsabwicklung erforderlich ist.
            </span>
          </p>
          <ul>
            <li>
              <span>Paypal</span>
            </li>
          </ul>
          <p>
            <span>
              Auf dieser Website stehen eine oder mehrere Online-Zahlungsarten
              des folgenden Anbieters zur Verfügung: PayPal (Europe) S.a.r.l. et
              Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg
            </span>
          </p>
          <p>
            <span>
              Bei Auswahl einer Zahlungsart des Anbieters, bei der Sie in
              Vorleistung gehen (etwa Kreditkartenzahlung), werden an diesen
              Ihre im Rahmen des Bestellvorgangs mitgeteilten Zahlungsdaten
              (darunter Name, Anschrift, Bank- und Zahlkarteninformationen,
              Währung und Transaktionsnummer) sowie Informationen über den
              Inhalt Ihrer Bestellung gemäß Art. 6 Abs. 1 lit. b DSGVO
              weitergegeben. Die Weitergabe Ihrer Daten erfolgt in diesem Falle
              ausschließlich zum Zweck der Zahlungsabwicklung mit dem Anbieter
              und nur insoweit, als sie hierfür erforderlich ist.
            </span>
          </p>
          <p>
            <span>
              Bei Auswahl einer Zahlungsart, bei der der Anbieter in Vorleistung
              geht (etwa Rechnungs- oder Ratenkauf bzw. Lastschrift), werden Sie
              im Bestellablauf auch aufgefordert, bestimmte persönliche Daten
              (Vor- und Nachname, Straße, Hausnummer, Postleitzahl, Ort,
              Geburtsdatum, E-Mail-Adresse, Telefonnummer, ggf. Daten zu einem
              alternativen Zahlungsmittel) anzugeben.
            </span>
          </p>
          <p>
            <span>
              Um unser berechtigtes Interesse an der Feststellung der
              Zahlungsfähigkeit unserer Kunden zu wahren, werden diese Daten von
              uns gemäß Art. 6 Abs. 1 lit. f DSGVO zum Zweck einer
              Bonitätsprüfung an den Anbieter weitergeleitet. Der Anbieter prüft
              auf Basis der von Ihnen angegebenen persönlichen Daten sowie
              weiterer Daten (wie etwa Warenkorb, Rechnungsbetrag,
              Bestellhistorie, Zahlungserfahrungen), ob die von Ihnen
              ausgewählte Zahlungsmöglichkeit im Hinblick auf Zahlungs- und/oder
              Forderungsausfallrisiken gewährt werden kann.
            </span>
          </p>
          <p>
            <span>
              Die Bonitätsauskunft kann Wahrscheinlichkeitswerte enthalten (sog.
              Score-Werte). Soweit Score-Werte in das Ergebnis der
              Bonitätsauskunft einfließen, haben sie ihre Grundlage in einem
              wissenschaftlich anerkannten mathematisch-statistischen Verfahren.
              In die Berechnung der Score-Werte fließen unter anderem, aber
              nicht ausschließlich, Anschriftendaten ein.
            </span>
          </p>
          <p>
            <span>
              Sie können dieser Verarbeitung Ihrer Daten jederzeit durch eine
              Nachricht an uns oder gegenüber dem Anbieter widersprechen. Jedoch
              bleibt der Anbieter ggf. weiterhin berechtigt, Ihre
              personenbezogenen Daten zu verarbeiten, sofern dies zur
              vertragsgemäßen Zahlungsabwicklung erforderlich ist.
            </span>
          </p>
          <h2>
            <span>8) Online-Marketing</span>
          </h2>
          <p>
            <strong>
              <span>8.1</span>
            </strong>
            <span> Facebook-Pixel zur Erstellung von Custom Audiences</span>
          </p>
          <p>
            <span>
              Innerhalb unseres Online-Angebots verwenden wir den Dienst
              "Facebook Pixel" des folgenden Anbieters: Meta Platforms Ireland
              Limited, 4 Grand Canal Quare, Dublin 2, Irland ("Facebook")
            </span>
          </p>
          <p>
            <span>
              Klickt ein Nutzer auf eine von uns auf Facebook geschaltete
              Werbeanzeige, wird mithilfe von "Facebook Pixel" die URL unserer
              verlinkten Seite um einen Parameter erweitert. Dieser
              URL-Parameter wird dann nach der Weiterleitung durch ein Cookie,
              das unsere verlinkte Seite selbst setzt, in den Browser des
              Nutzers eingetragen.
            </span>
          </p>
          <p>
            <span>
              Dadurch ist es einerseits Facebook möglich, die Besucher unseres
              Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen
              (sog. "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir den
              Dienst ein, um die durch uns geschalteten Facebook-Ads nur solchen
              Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem
              Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B.
              Interessen an bestimmten Themen oder Produkten, die anhand der
              besuchten Webseiten bestimmt werden) aufweisen, welche wir an
              Facebook übermitteln (sog. „Custom Audiences“).
            </span>
          </p>
          <p>
            <span>
              Andererseits kann mit „Facebook Pixel“ nachvollzogen werden, ob
              Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf unsere
              Website weitergeleitet wurden und welche Ausführungshandlungen sie
              dort tätigen (sog. „Conversion-Tracking“).
            </span>
          </p>
          <p>
            <span>
              Die erhobenen Daten sind für uns anonym, bieten uns also keine
              Rückschlüsse auf die Identität der Nutzer. Allerdings werden die
              Daten von Facebook gespeichert und verarbeitet, sodass eine
              Verbindung zum jeweiligen Nutzerprofil möglich ist und Facebook
              die Daten für eigene Werbezwecke verwenden kann.
            </span>
          </p>
          <p>
            <span>
              Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen
              von Cookies für das Auslesen von Informationen auf dem verwendeten
              Endgerät, werden nur dann vollzogen, wenn Sie uns gemäß Art. 6
              Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche Einwilligung erteilt
              haben. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung
              für die Zukunft widerrufen, indem Sie diesen Dienst in dem auf der
              Webseite bereitgestellten „Cookie-Consent-Tool“ deaktivieren.
            </span>
          </p>
          <p>
            <span>
              Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
              geschlossen, der den Schutz der Daten unserer Seitenbesucher
              sicherstellt und eine unberechtigte Weitergabe an Dritte
              untersagt.
            </span>
          </p>
          <p>
            <span>
              Die durch Facebook erzeugten Informationen werden in der Regel an
              einen Server von Facebook übertragen und dort gespeichert; in
              diesem Zusammenhang kann es auch zu einer Übertragung an Server
              von Meta Platforms Inc. in den USA kommen.
            </span>
          </p>
          <p>
            <span>
              Für die Übermittlung von Daten in die USA beruft sich der Anbieter
              auf Standardvertragsklauseln der Europäischen Kommission, welche
              die Einhaltung des europäischen Datenschutzniveaus sicherzustellen
              sollen.
            </span>
          </p>
          <p>
            <strong>
              <span>8.2</span>
            </strong>
            <span> Google AdSense</span>
          </p>
          <p>
            <span>
              Diese Website benutzt Google AdSense, einen Webanzeigendienst
              Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04
              E5W5, Irland ("Google"). Google AdSense verwendet sog. Cookies,
              also Textdateien, die auf Ihrem Computer gespeichert werden und
              die eine Analyse der Benutzung der Website durch Sie ermöglichen.
              Darüber hinaus verwendet Google AdSense zur Sammlung von
              Informationen auch sog. "Web-Beacons" (kleine unsichtbare
              Grafiken), durch deren Verwendung einfache Aktionen wie der
              Besucherverkehr auf der Website aufgezeichnet, gesammelt und
              ausgewertet werden können. Die durch das Cookie und/ oder
              Web-Beacon erzeugten Informationen (einschließlich Ihrer
              IP-Adresse) über Ihre Benutzung dieser Website werden in der Regel
              an einen Server von Google übertragen und dort gespeichert.
              Hierbei kann es auch zu einer Übermittlung an die Server der
              Google LLC. in den USA kommen.
            </span>
          </p>
          <p>
            <span>
              Google benutzt die so erhaltenen Informationen, um eine Auswertung
              Ihres Nutzungsverhaltens im Hinblick auf die AdSense-Anzeigen
              durchzuführen. Die im Rahmen von Google AdSense von Ihrem Browser
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google
              zusammengeführt. Die von Google erhobenen Informationen werden
              unter Umständen an Dritte übertragen, sofern dies gesetzlich
              vorgeschrieben ist und/ oder soweit Dritte diese Daten im Auftrag
              von Google verarbeiten.
            </span>
          </p>
          <p>
            <span>
              Alle oben beschriebenen Verarbeitungen, insbesondere Auslesen von
              Informationen auf dem verwendeten Endgerät über Cookies und/oder
              Web-Beacons, werden nur dann vollzogen, wenn Sie uns gemäß Art. 6
              Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche Einwilligung erteilt
              haben. Ohne diese Einwilligungserteilung unterbleibt der Einsatz
              von Google AdSense während Ihres Seitenbesuchs.
            </span>
          </p>
          <p>
            <span>
              Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für
              die Zukunft widerrufen, indem Sie diesen Dienst in dem auf der
              Webseite bereitgestellten „Cookie-Consent-Tool“ deaktivieren.
            </span>
          </p>
          <p>
            <span>Googles Datenschutzbestimmungen sind hier einsehbar: </span>
            <a href="https://www.google.de/policies/privacy/">
              <span>https://www.google.de/policies/privacy/</span>
            </a>
          </p>
          <h2>
            <span>9) Seitenfunktionalitäten</span>
          </h2>
          <p>
            <span>Google Web Fonts</span>
          </p>
          <p>
            <span>
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              sogenannte Web Fonts des folgenden Anbieters: Google Ireland
              Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland
            </span>
          </p>
          <p>
            <span>
              Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts
              in ihren Browser-Cache, um Texte und Schriftarten korrekt
              anzuzeigen und stellt eine direkte Verbindung zu den Servern des
              Anbieters her. Hierbei werden bestimmte Browserinformationen,
              einschließlich Ihrer IP-Adresse, an den Anbieter übermittelt.
            </span>
          </p>
          <p>
            <span>
              Daten können zudem übermittelt werden an: Google LLC, USA
            </span>
          </p>
          <p>
            <span>
              Die Verarbeitung von personenbezogenen Daten im Zuge der
              Verbindungsaufnahme mit dem Anbieter der Schriftarten wird nur
              dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu
              Ihre ausdrückliche Einwilligung erteilt haben. Sie können Ihre
              erteilte Einwilligung jederzeit mit Wirkung für die Zukunft
              widerrufen, indem Sie diesen Dienst über das auf der Webseite
              bereitgestellte „Cookie-Consent-Tool“ deaktivieren. Falls Ihr
              Browser keine Web Fonts unterstützt, wird eine Standardschrift von
              Ihrem Computer genutzt.
            </span>
          </p>
          <p>
            <span>
              Für die Übermittlung von Daten in die USA beruft sich der Anbieter
              auf Standardvertragsklauseln der Europäischen Kommission, welche
              die Einhaltung des europäischen Datenschutzniveaus sicherzustellen
              sollen.
            </span>
          </p>
          <h2>
            <span>10) Rechte des Betroffenen</span>
          </h2>
          <p>
            <strong>
              <span>10.1</span>
            </strong>
            <span>
               Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem
              Verantwortlichen hinsichtlich der Verarbeitung Ihrer
              personenbezogenen Daten die nachstehenden Betroffenenrechte
              (Auskunfts- und Interventionsrechte), wobei für die jeweiligen
              Ausübungsvoraussetzungen auf die angeführte Rechtsgrundlage
              verwiesen wird:
            </span>
          </p>
          <ul>
            <li>
              <span>Auskunftsrecht gemäß Art. 15 DSGVO;</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Recht auf Berichtigung gemäß Art. 16 DSGVO;</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Recht auf Löschung gemäß Art. 17 DSGVO;</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO;
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Recht auf Unterrichtung gemäß Art. 19 DSGVO;</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO;</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3
                DSGVO;
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Recht auf Beschwerde gemäß Art. 77 DSGVO.</span>
            </li>
          </ul>
          <p>
            <strong>
              <span>10.2</span>
            </strong>
            <span> WIDERSPRUCHSRECHT</span>
          </p>
          <p>
            <span>
              WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN
              DATEN AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES
              VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE
              SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE
              VERARBEITUNG WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.
            </span>
          </p>
          <p>
            <span>
              MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
              VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT
              ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
              VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE
              UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER
              GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
              DIENT.
            </span>
          </p>
          <p>
            <span>
              WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
              DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT
              WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
              PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN.
              SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUSÜBEN.
            </span>
          </p>
          <p>
            <span>
              MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
              VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
            </span>
          </p>
          <h2>
            <span>11) Dauer der Speicherung personenbezogener Daten</span>
          </h2>
          <p>
            <span>
              Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
              anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und –
              sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen
              Aufbewahrungsfrist (z.B. handels- und steuerrechtliche
              Aufbewahrungsfristen).
            </span>
          </p>
          <p>
            <span>
              Bei der Verarbeitung von personenbezogenen Daten auf Grundlage
              einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO
              werden die betroffenen Daten so lange gespeichert, bis Sie Ihre
              Einwilligung widerrufen.
            </span>
          </p>
          <p>
            <span>
              Existieren gesetzliche Aufbewahrungsfristen für Daten, die im
              Rahmen rechtsgeschäftlicher bzw. rechtsgeschäftsähnlicher
              Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO
              verarbeitet werden, werden diese Daten nach Ablauf der
              Aufbewahrungsfristen routinemäßig gelöscht, sofern sie nicht mehr
              zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind
              und/oder unsererseits kein berechtigtes Interesse an der
              Weiterspeicherung fortbesteht.
            </span>
          </p>
          <p>
            <span>
              Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange
              gespeichert, bis Sie Ihr Widerspruchsrecht nach Art. 21 Abs. 1
              DSGVO ausüben, es sei denn, wir können zwingende schutzwürdige
              Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
              Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </span>
          </p>
          <p>
            <span>
              Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
              Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
              diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht
              nach Art. 21 Abs. 2 DSGVO ausüben.
            </span>
          </p>
          <p>
            <span>
              Sofern sich aus den sonstigen Informationen dieser Erklärung über
              spezifische Verarbeitungssituationen nichts anderes ergibt, werden
              gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn
              sie für die Zwecke, für die sie erhoben oder auf sonstige Weise
              verarbeitet wurden, nicht mehr notwendig sind.
            </span>
          </p>
        </div>
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
