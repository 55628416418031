import { styled } from '@mui/system';

export const CartContainer = styled('div')(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
  marginBottom: theme.spacing(3),
  display: 'flex', // Set the display to flex
  flexDirection: 'column', // Align items vertically
  justifyContent: 'center', // Center items horizontally
  alignItems: 'center', // Center items vertically
  padding: theme.spacing(6),
}));

export const PageTitle = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  color: theme.palette.text.primary,
}));

export const CartItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  margin: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  maxWidth: '1400px',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row', // Change to 'row' on small screens
    flexWrap: 'wrap', // Allow items to wrap onto the next line if there's not enough space
  },
}));

export const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginTop: theme.spacing(-3), // Reduced the margin bottom
  },
}));

export const ItemTitle = styled('h1')(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(-2), // Reduced the margin bottom
  },
}));

export const ItemPrice = styled('h2')(({ theme }) => ({
  flex: 0.5,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(-1),
  },
}));

export const ItemColor = styled('h2')(({ theme }) => ({
  flex: 0.5,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(-3), // Reduced the margin bottom
  },
}));

export const ItemSize = styled('h2')(({ theme }) => ({
  flex: 0.5,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(-3),
  },
}));

export const ItemQuantity = styled('h2')(({ theme }) => ({
  flex: 0.5,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(-3),
  },
}));

export const ItemImage = styled('img')(({ theme }) => ({
  flex: 1,
  maxWidth: '140px',
  height: 'auto', // This will maintain the aspect ratio of the image
  marginRight: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(0),
    maxWidth: '160px',
  },
}));

export const TotalPrice = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  marginTop: theme.spacing(4),
  color: theme.palette.text.primary,
}));
