import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  html, body, #root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: 'Teko', sans-serif; // DEFINE IN PUBLIC/INDEX.HTML IMPORT LINK
    background-color: #f0f0f0; // Set a default background color
  }
`;
