import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  VideoContainer,
  FullscreenVideo,
  ShopNowButton,
} from './FullScreenVideoStyles';

interface FullScreenVideoProps {
  srcMp4: string;
  srcWebm: string;
}

const FullScreenVideoComponent: React.FC<FullScreenVideoProps> = ({
  srcMp4,
  srcWebm,
}) => {
  const navigate = useNavigate();
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    setIsPortrait(window.innerHeight / window.innerWidth > 1);
    const handleResize = () => {
      setIsPortrait(window.innerHeight / window.innerWidth > 1);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleButtonClick = () => {
    navigate('/space-collection');
  };

  return (
    <VideoContainer>
      <FullscreenVideo autoPlay muted loop playsInline preload="auto">
        <source src={srcWebm} type="video/webm" />
        <source src={srcMp4} type="video/mp4" />
      </FullscreenVideo>
      <ShopNowButton onClick={handleButtonClick}>Free Shipping</ShopNowButton>
    </VideoContainer>
  );
};

export default FullScreenVideoComponent;
