import React from 'react';
import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { IconButton, Badge } from '@mui/material';
import {
  HeaderContainer,
  Navigation,
  NavLink,
  StyledTextLogo,
  StyledStarLogo,
} from './HeaderStyles';
import { useCart } from '../../CartContext';

function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { cart } = useCart();

  const isMediumScreen = useMediaQuery('(min-width: 600px)'); // Adjust the query according to your needs

  const handleCartClick = () => {
    navigate('/cart');
  };

  const itemCount = cart.reduce((count, item) => count + item.quantity, 0);

  return (
    <HeaderContainer>
      <Link style={{ margin: '16px 0 8px 32px' }} to="/">
        {isMediumScreen ? (
          <StyledTextLogo size={'extra_large'} color={'white'} />
        ) : (
          <StyledStarLogo size={'medium'} color={'white'} />
        )}
      </Link>
      <Navigation>
        <NavLink
          to="/space-collection"
          sx={{ '&:hover': { transform: 'scale(1.1)' } }}
        >
          Interstellar Collection
        </NavLink>
        <NavLink to="/lookbook" sx={{ '&:hover': { transform: 'scale(1.1)' } }}>
          Lookbook
        </NavLink>
        <IconButton
          onClick={handleCartClick}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
              transform: 'scale(1.2)',
            },
          }}
        >
          <Badge badgeContent={itemCount} color="primary">
            <ShoppingCartIcon sx={{ color: '#f5f5f5' }} />
          </Badge>
        </IconButton>
      </Navigation>
    </HeaderContainer>
  );
};

export default Header;
