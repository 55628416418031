import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

export const ContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

export const MaintenanceText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

export const ContainerButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const ContainerText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2),
}));

export const ButtonMargin = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5),
  visibility: 'hidden',
}));
