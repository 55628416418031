import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import TextLogo from '../Logos/TextLogo';
import StarLogo from '../Logos/StarLogo';

export const HeaderContainer = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.dark,
  boxSizing: 'border-box', // include padding and border in element's total width and height
}));

export const StyledTextLogo = styled(TextLogo)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

export const StyledStarLogo = styled(StarLogo)(({ theme }) => ({
  display: 'block',

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const Navigation = styled('nav')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(1),
  },
}));

export const NavLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.light,
  textDecoration: 'none',
  marginRight: theme.spacing(4),
  fontSize: '1.5rem',

  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(2),
    fontSize: '1.3rem',
  },
}));
