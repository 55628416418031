import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const HomepageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
}));

export const PageLinksContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  backgroundColor: '#fff',
}));

export const PageLink = styled('a')(({ theme }) => ({
  flex: '0 0 50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxSizing: 'border-box',
  textDecoration: 'none',
  fontSize: '1.5rem',
  color: '#000',
  position: 'relative',
}));

export const PageLinkImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxWidth: '500px', // Set the maximum width here
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius, // Add border radius here
}));

export const TextOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s',
  opacity: 0,
  borderRadius: theme.shape.borderRadius, // Add border radius here
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: 1,
  },
  '& > *': {
    transition: 'all 0.3s',
    transform: 'scale(1)',
  },
  '&:hover > *': {
    transform: 'scale(1.5)',
  },
}));
