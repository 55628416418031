import { styled } from '@mui/system';
import { Grid, Checkbox } from '@mui/material';

export const PaymentFormGridContainer = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const Spacer = styled('div')(({ theme }) => ({
  margin: theme.spacing(3),
  visibility: 'hidden',
}));
