import React from 'react';
import {
  FooterContainer,
  FooterLink,
  IconContainer,
  LinkContainer,
  SocialMediaIcon,
} from './FooterStyles';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <p>© {new Date().getFullYear()} Paraline. All Rights Reserved.</p>
      <LinkContainer>
        <FooterLink
          to="/shipping"
          sx={{ '&:hover': { transform: 'scale(1.1)' } }}
        >
          Shipping
        </FooterLink>
        <FooterLink
          to="/faq-contact"
          sx={{ '&:hover': { transform: 'scale(1.1)' } }}
        >
          FAQ & Contact
        </FooterLink>
        <FooterLink
          to="/privacy-policy"
          sx={{ '&:hover': { transform: 'scale(1.1)' } }}
        >
          Privacy Policy
        </FooterLink>
        <FooterLink
          to="/terms-and-conditions"
          sx={{ '&:hover': { transform: 'scale(1.1)' } }}
        >
          Terms and Conditions
        </FooterLink>
        <FooterLink
          to="/legal-notice"
          sx={{ '&:hover': { transform: 'scale(1.1)' } }}
        >
          Legal Notice
        </FooterLink>
      </LinkContainer>
      <IconContainer>
        <SocialMediaIcon
          href="https://www.instagram.com/paraline.de"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon
            fontSize="small"
            sx={{ '&:hover': { transform: 'scale(1.2)' } }}
          />
        </SocialMediaIcon>
        <SocialMediaIcon
          href="https://www.tiktok.com/@paraline.label"
          target="_blank"
          rel="noreferrer"
        >
          <MusicNoteIcon
            sx={{ '&:hover': { transform: 'scale(1.2)' } }}
            fontSize="small"
          />
        </SocialMediaIcon>
        <SocialMediaIcon
          href="https://www.youtube.com/@paraline3780"
          target="_blank"
          rel="noreferrer"
        >
          <YouTubeIcon
            fontSize="small"
            sx={{ '&:hover': { transform: 'scale(1.2)' } }}
          />
        </SocialMediaIcon>
      </IconContainer>
    </FooterContainer>
  );
};

export default Footer;
