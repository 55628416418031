import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

export const Container = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.primary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  flexDirection: 'row',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
}));

export const ProductImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('sm')]: {
    maxWidth: '50%',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '30%',
  },
}));

export const ProductInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: theme.spacing(2),
}));

export const ColorFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  border: 'none',
}));

export const SizeFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  border: 'none',
}));

export const ImageSelector = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginBottom: theme.spacing(4),
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100px',
  height: '100px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    width: '80px',
    height: '80px',
  },
}));

export const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  margin: 'auto',
  borderRadius: theme.shape.borderRadius,
}));

export const SizeGuideText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const ProductTitle = styled(Typography)(({ theme }) => ({}));

export const ProductDescription = styled(Typography)(({ theme }) => ({}));

export const ProductPrice = styled(Typography)(({ theme }) => ({}));
