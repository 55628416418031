import { AppBar, Button, Container, Paper, Step, Stepper } from '@mui/material';
import { styled } from '@mui/system';

export const StyledAppBar = styled(AppBar)`
  position: relative;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`;

export const StyledMainContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledPaper = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${({ theme }) => theme.spacing(6)};
    margin-bottom: ${({ theme }) => theme.spacing(6)};
    padding: ${({ theme }) => theme.spacing(3)};
  }
`;

export const StyledStepper = styled(Stepper)`
  padding: ${({ theme }) => theme.spacing(3, 0, 5)};
`;

export const StyledStep = styled(Step)``;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
