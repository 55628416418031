import React from 'react';
import { LogoImage } from './StarLogoStyles';
import StarLogoBlack from '../../assets/logo/StarLogoBlack.png';
import StarLogoWhite from '../../assets/logo/StarLogoWhite.png';

interface LogoProps {
  size: 'small' | 'medium' | 'large' | 'extra_large';
  color: 'black' | 'white';
}

const StarLogo: React.FC<LogoProps> = ({ size, color }) => {
  let logoSize;
  switch (size) {
    case 'small':
      logoSize = '50px';
      break;
    case 'medium':
      logoSize = '100px';
      break;
    case 'large':
      logoSize = '150px';
      break;
    case 'extra_large':
      logoSize = '300px';
      break;
    default:
      logoSize = '100px';
      break;
  }

  let logoImage;
  switch (color) {
    case 'black':
      logoImage = StarLogoBlack;
      break;
    case 'white':
      logoImage = StarLogoWhite;
      break;
    default:
      logoImage = StarLogoBlack;
      break;
  }

  return (
    <LogoImage src={logoImage} alt="StarLogo" style={{ width: logoSize }} />
  );
};

export default StarLogo;
