import React from 'react';
import { Typography, Container } from '@mui/material';

const Shipping: React.FC = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Versand (Shipping)
      </Typography>
      <Typography variant="h3" gutterBottom>
        Versandkosten:
      </Typography>
      <Typography variant="h5" gutterBottom>
        Versand ist bei Paraline in ganz Europa Gratis
      </Typography>
      <Typography variant="h3" gutterBottom>
        Lieferzeit:
      </Typography>
      <Typography variant="h5" gutterBottom>
        Du erhältst Standardbestellungen in Deutschland in der Regel 2 bis 4
        Werktage nach Eingang der Bestellung. Bei anderen Ländern kommt es zu
        entsprechenden Verzögerungen. Kurz nach Release einer Kollektion kann es
        zu längeren Lieferzeiten kommen. Sobald Deine Bestellung auf dem Weg zu
        Dir ist, erhältst Du eine separate Versandbestätigung per E-Mail.{' '}
      </Typography>

      <Typography variant="h3" gutterBottom>
        Standort:
      </Typography>
      <Typography variant="h5" gutterBottom>
        Gewerbering 7, 83370 Seeon, Deutschland
      </Typography>
    </Container>
  );
};

export default Shipping;
