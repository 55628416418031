// React
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Firebase
import { rdb } from './firebase/firebaseConfig';
import { AuthProvider } from './firebase/AuthContext';
import { onValue, ref } from 'firebase/database';
import 'firebase/compat/database';

// Context
import { CartProvider } from './CartContext';

// pages
import HomePage from './pages/HomePage/HomePage';
import LookBook from './pages/LookBook/LookBook';
import SpaceCollection from './pages/CurrentCollection/SpaceCollection';
import SingleProduct from './pages/SingleProduct/SingleProduct';
import Checkout from './pages/Checkout/Checkout';
import Socials from './pages/Socials/Socials';
import Profile from './pages/Authentication/Profile';
import { CartPage } from './pages/CartPage/CartPage';
import MaintenancePage from './pages/MaintenancePage/MaintenancePage';

// pages - Authentication
import SignUp from './pages/Authentication/SignUp';
import SignIn from './pages/Authentication/SignIn';

// pages - Admin
import AdminDashboard from './admin/AdminDashboard';
import AdminLayout from './admin/AdminLayout';

// pages - Footer
import Shipping from './components/Footer/pages/Shipping';
import FAQContact from './components/Footer/pages/FAQContact';
import PrivacyPolicy from './components/Footer/pages/PrivacyPolicy';
import TermsConditions from './components/Footer/pages/TermsConditions';
import LegalNotice from './components/Footer/pages/LegalNotice';

// Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

// Theme and styles
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { AppContainer } from './AppStyles';
import { GlobalStyle } from './GlobalStyle';

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Stripe key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

// Main app component
const App: React.FC = () => {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const [hasEarlyAccess, setHasEarlyAccess] = useState(false);

  useEffect(() => {
    // Create a reference to the '/maintenanceMode' path in the Realtime Database
    const maintenanceRef = ref(rdb, '/maintenanceMode');

    // Listen for changes to the value at the '/maintenanceMode' path
    onValue(maintenanceRef, snapshot => {
      setIsUnderMaintenance(!!snapshot.val());
    });
    // Firebase Realtime Database does not need explicit listener clean-up
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <Router>
          <GlobalStyle />
          <AppContainer>
            {isUnderMaintenance && !hasEarlyAccess ? (
              <MaintenancePage onGrantAccess={() => setHasEarlyAccess(true)} />
            ) : (
              <>
                <CartProvider>
                  <Header />
                  <AuthProvider>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/lookbook" element={<LookBook />} />
                      <Route
                        path="/space-collection"
                        element={<SpaceCollection />}
                      />
                      <Route
                        path="/space-collection/:id"
                        element={<SingleProduct />}
                      />
                      <Route path="/cart" element={<CartPage />} />
                      <Route path="/checkout" element={<Checkout />} />
                      <Route path="/socials" element={<Socials />} />
                      {/*<Route path="/signup" element={<SignUp />} />*/}
                      {/*<Route path="/signin" element={<SignIn />} />*/}
                      {/*<Route path="/profile" element={<Profile />} />*/}
                      {/*<Route path="/admin" element={<AdminLayout />}>*/}
                      {/*  <Route index element={<AdminDashboard />} />*/}
                      {/*</Route>*/}
                      <Route path="/shipping" element={<Shipping />} />
                      <Route path="/faq-contact" element={<FAQContact />} />
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route
                        path="/terms-and-conditions"
                        element={<TermsConditions />}
                      />
                      <Route path="/legal-notice" element={<LegalNotice />} />
                    </Routes>
                  </AuthProvider>
                  <Footer />
                </CartProvider>
              </>
            )}
          </AppContainer>
        </Router>
      </Elements>
    </ThemeProvider>
  );
};

export default App;
