import { styled } from '@mui/system';

export const VideoContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  background: 'black',
}));

export const FullscreenVideo = styled('video')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover', // will cover the screen in both orientations
}));

export const ShopNowButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  bottom: '30%', // adjust as necessary, higher percentage makes it appear higher
  left: '50%',
  transform: 'translateX(-50%)',
  background: 'transparent',
  border: `2px solid ${theme.palette.text.light}`, // adjust border thickness as necessary
  color: theme.palette.text.light,
  padding: theme.spacing(3, 4), // increase the padding for more space
  fontFamily: theme.typography.fontFamily, // apply the theme's font family
  fontSize: theme.typography.h4.fontSize, // increase the font size
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s ease', // add this to make the transformation smoother
  '&:hover': {
    transform: 'translateX(-50%) scale(1.1)', // change 1.1 to the scaling factor you prefer
  },
  // Responsive styles for mobile devices
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 3), // reduce the padding for smaller space
    fontSize: theme.typography.h5.fontSize, // decrease the font size
    border: `1px solid ${theme.palette.text.light}`, // adjust border thickness as necessary
    bottom: '25%', // adjust as necessary, higher percentage makes it appear higher
  },
}));
