import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const SocialsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(3)};
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const SocialsHeading = styled('h1')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const SocialsSubHeading = styled('h2')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.secondary};
`;
