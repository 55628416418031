import * as React from 'react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {
  AddressFormGridContainer,
  AddressFormCheckbox,
} from './AddressFormStyles';
import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';

interface AddressFormProps {
  formValues: any;
  updateFormValues: (formValues: any) => void;
  submitRef: React.MutableRefObject<(() => void) | null>;
  hidden: boolean;
  setAddressFormIsValid: (isValid: boolean) => void;
}

export default function AddressForm({
  formValues,
  updateFormValues,
  submitRef,
  hidden,
  setAddressFormIsValid,
}: AddressFormProps) {
  const [localFormValues, setLocalFormValues] = useState(formValues);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    // Check whether the required fields are filled
    const isValid =
      localFormValues.firstName &&
      localFormValues.lastName &&
      localFormValues.email &&
      localFormValues.address1 &&
      localFormValues.city &&
      localFormValues.zip &&
      localFormValues.country;

    // Regex pattern for a basic email check
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z|a-z]{2,}$/i;

    // Check if the email is in the correct format
    const isEmailValid = emailPattern.test(localFormValues.email);

    setFormIsValid(isValid && isEmailValid);
    setAddressFormIsValid(isValid && isEmailValid);
  }, [localFormValues]);

  useEffect(() => {
    submitRef.current = () => {
      if (formIsValid) {
        // Only update form values if form is valid
        updateFormValues(localFormValues);
      }
    };
  }, [submitRef, formIsValid, localFormValues, updateFormValues]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalFormValues({ ...localFormValues, [name]: value });
  };

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setLocalFormValues({ ...localFormValues, country: value });
  };

  const handleFormSubmit = () => {
    updateFormValues(localFormValues);
  };

  // Make the function available through the ref
  useEffect(() => {
    submitRef.current = handleFormSubmit;
  }, [submitRef, handleFormSubmit]);

  return hidden ? null : (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <AddressFormGridContainer container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="outlined"
            value={localFormValues.firstName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="outlined"
            value={localFormValues.lastName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            variant="outlined"
            value={localFormValues.email}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
            variant="outlined"
            value={localFormValues.address1}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            variant="outlined"
            value={localFormValues.address2}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            variant="outlined"
            value={localFormValues.city}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="outlined"
            value={localFormValues.state}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            variant="outlined"
            value={localFormValues.zip}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              required
              labelId="country-label"
              id="country"
              value={localFormValues.country}
              onChange={handleCountryChange}
              label="Country"
              variant="outlined"
            >
              <MenuItem value="Austria">Austria</MenuItem>
              <MenuItem value="Belgium">Belgium</MenuItem>
              <MenuItem value="Bulgaria">Bulgaria</MenuItem>
              <MenuItem value="Croatia">croatia</MenuItem>
              <MenuItem value="Czechia">Czechia</MenuItem>
              <MenuItem value="Denmark">Denmark</MenuItem>
              <MenuItem value="Finland">Finland</MenuItem>
              <MenuItem value="France">France</MenuItem>
              <MenuItem value="Germany">Germany</MenuItem>
              <MenuItem value="Greece">Greece</MenuItem>
              <MenuItem value="Hungary">Hungary</MenuItem>
              <MenuItem value="Italy">Italy</MenuItem>
              <MenuItem value="Netherlands">Netherlands</MenuItem>
              <MenuItem value="Poland">Poland</MenuItem>
              <MenuItem value="Portugal">Portugal</MenuItem>
              <MenuItem value="Romania">Romania</MenuItem>
              <MenuItem value="Slovakia">Slovakia</MenuItem>
              <MenuItem value="Slovenia">Slovenia</MenuItem>
              <MenuItem value="Spain">Spain</MenuItem>
              <MenuItem value="Sweden">Sweden</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </AddressFormGridContainer>
    </React.Fragment>
  );
}
