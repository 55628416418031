import React, { FC } from 'react';
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from '@paypal/react-paypal-js';
import { db } from '../../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useCart, CartItem } from '../../CartContext';

interface PaypalButtonProps {
  amount: string;
  onSuccess: () => void;
}

const PaypalButton: FC<PaypalButtonProps> = ({ amount, onSuccess }) => {
  const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

  if (!clientId) {
    throw new Error(
      'Environment variable REACT_APP_PAYPAL_CLIENT_ID is not set!'
    );
  }

  const { cart } = useCart();

  const options = {
    clientId,
    intent: 'capture',
    currency: 'EUR',
  };

  const checkItemsAvailability = async (cart: CartItem[]) => {
    for (const item of cart) {
      const docRef = doc(db, 'products', item.id);
      const productSnap = await getDoc(docRef);

      if (!productSnap.exists()) {
        window.alert(`Product ${item.id} not found`);
        return false;
      }

      const productData = productSnap.data();

      const color = productData.colors.find((c: any) => c.color === item.color);
      const size = color.sizes.find((s: any) => s.size === item.size);

      if (size.stock < item.quantity) {
        window.alert(
          `Product ${item.id} - ${item.color} - ${item.size} is out of stock`
        );
        return false;
      }
    }
    return true;
  };

  return (
    <PayPalScriptProvider options={options}>
      <PayPalButtons
        fundingSource={FUNDING.PAYPAL}
        style={{
          layout: 'vertical',
          shape: 'rect',
          color: 'white',
        }}
        createOrder={(data, actions) => {
          return actions.order?.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                },
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          const areItemsAvailable = await checkItemsAvailability(cart);
          if (!areItemsAvailable) {
            return;
          }

          if (actions.order) {
            return actions.order.capture().then(details => {
              onSuccess();
            });
          }

          return Promise.resolve();
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalButton;
