import React, { useRef, useEffect } from 'react';
import './ImageTrack.css';

interface ImageTrackProps {
  images: string[];
  speed: number;
  direction: 'left' | 'right' | 'none';
}

const ImageTrack: React.FC<ImageTrackProps> = ({
  images,
  speed,
  direction,
}) => {
  const trackRef = useRef<HTMLDivElement>(null);

  // Constant Sliding Animation Start
  const animationRef = useRef<number>();

  const updateTrackPosition = (nextPercentage: number) => {
    if (trackRef.current) {
      trackRef.current.dataset.percentage = nextPercentage.toString();
      trackRef.current.style.transform = `translate(${nextPercentage}%, -50%)`;

      const images = trackRef.current.getElementsByClassName('image');
      for (let i = 0; i < images.length; i++) {
        const image = images[i] as HTMLImageElement;
        image.style.objectPosition = `${100 + nextPercentage}% center`;
      }
    }
  };

  const animate = () => {
    if (direction === 'none') {
      return;
    }
    const currentPercentage = parseFloat(
      trackRef.current?.dataset.percentage || '0'
    );
    const delta = (direction === 'left' ? -1 : 1) * speed;
    const nextPercentage = Math.max(
      Math.min(currentPercentage + delta, 0),
      -100
    );

    updateTrackPosition(nextPercentage);

    animationRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(animationRef.current as number);
    };
  }, [direction]);

  // Constant Sliding Animation End

  const handleOnDown = (e: React.MouseEvent) => {
    if (trackRef.current) {
      trackRef.current.dataset.mouseDownAt = e.clientX.toString();
    }
  };

  const handleOnUp = () => {
    if (trackRef.current) {
      trackRef.current.dataset.mouseDownAt = '0';
      trackRef.current.dataset.prevPercentage =
        trackRef.current.dataset.percentage;
    }
  };

  const handleOnMove = (e: React.MouseEvent) => {
    if (!trackRef.current || trackRef.current.dataset.mouseDownAt === '0')
      return;

    const mouseDownAt = trackRef.current.dataset.mouseDownAt || '0';
    const prevPercentage = trackRef.current.dataset.prevPercentage || '0';

    const mouseDelta = parseFloat(mouseDownAt) - e.clientX,
      maxDelta = window.innerWidth / 2;

    const percentage = (mouseDelta / maxDelta) * -100,
      nextPercentageUnconstrained = parseFloat(prevPercentage) + percentage,
      nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);

    if (trackRef.current) {
      trackRef.current.dataset.percentage = nextPercentage.toString();

      trackRef.current.style.transform = `translate(${nextPercentage}%, -50%)`;

      const images = trackRef.current.getElementsByClassName('image');

      for (let i = 0; i < images.length; i++) {
        const image = images[i] as HTMLImageElement;
        image.style.objectPosition = `${100 + nextPercentage}% center`;
      }
    }
  };

  return (
    <div>
      <div
        id="image-track"
        ref={trackRef}
        data-mouse-down-at="0"
        data-prev-percentage="0"
        onMouseDown={handleOnDown}
        onMouseUp={handleOnUp}
        onMouseMove={handleOnMove}
      >
        {images.map((image, index) => (
          <img
            key={index}
            className="image"
            src={image}
            draggable="false"
            alt={`Image ${index + 1}`}
            style={{ borderRadius: '4px' }} // Here we add the border radius
          />
        ))}
      </div>
    </div>
  );
};

export default ImageTrack;
