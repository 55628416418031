import React from 'react';
import ImageTrack from '../../components/ImageTrack/ImageTrack';
import {
  ImageTrackWrapper,
  LookbookContainer,
  LookbookHeading,
  SlidersContainer,
} from './LookBookStyles';

import interstellar1 from '../../assets/images/Interstellar/interstellar1.jpg';
import interstellar2 from '../../assets/images/Interstellar/interstellar2.jpg';
import interstellar3 from '../../assets/images/Interstellar/interstellar3.jpg';
import interstellar4 from '../../assets/images/Interstellar/interstellar4.jpg';
import interstellar5 from '../../assets/images/Interstellar/interstellar5.jpg';
import interstellar6 from '../../assets/images/Interstellar/interstellar6.jpg';
import interstellar7 from '../../assets/images/Interstellar/interstellar7.jpg';
import interstellar8 from '../../assets/images/Interstellar/interstellar8.jpg';
import interstellar9 from '../../assets/images/Interstellar/interstellar9.jpg';
import interstellar10 from '../../assets/images/Interstellar/interstellar10.jpg';

import osr1 from '../../assets/images/OSR/osr1.jpg';
import osr2 from '../../assets/images/OSR/osr2.jpg';
import osr3 from '../../assets/images/OSR/osr3.jpg';
import osr4 from '../../assets/images/OSR/osr4.jpg';
import osr5 from '../../assets/images/OSR/osr5.jpg';
import osr6 from '../../assets/images/OSR/osr6.jpg';
import osr7 from '../../assets/images/OSR/osr7.jpg';
import osr8 from '../../assets/images/OSR/osr8.jpg';
import osr10 from '../../assets/images/OSR/osr10.jpg';
import osr11 from '../../assets/images/OSR/osr11.jpg';
import osr13 from '../../assets/images/OSR/osr13.jpg';
import osr14 from '../../assets/images/OSR/osr14.jpg';

import colorstone1 from '../../assets/images/Colorstone/colorstone1.jpg';
import colorstone2 from '../../assets/images/Colorstone/colorstone2.jpg';
import colorstone3 from '../../assets/images/Colorstone/colorstone3.jpg';
import colorstone4 from '../../assets/images/Colorstone/colorstone4.jpg';
import colorstone5 from '../../assets/images/Colorstone/colorstone5.jpg';
import colorstone6 from '../../assets/images/Colorstone/colorstone6.jpg';
import colorstone7 from '../../assets/images/Colorstone/colorstone7.jpg';
import colorstone8 from '../../assets/images/Colorstone/colorstone8.jpg';
import colorstone9 from '../../assets/images/Colorstone/colorstone9.jpg';

const LookBook: React.FC = () => {
  return (
    <LookbookContainer>
      <LookbookHeading>Lookbook</LookbookHeading>
      <SlidersContainer>
        <ImageTrackWrapper>
          <ImageTrack
            images={[
              interstellar4,
              interstellar5,
              interstellar3,
              interstellar9,
              interstellar7,
              interstellar6,
              interstellar2,
              interstellar8,
              interstellar1,
              interstellar10,
            ]}
            speed={0.021}
            direction="left"
          />
        </ImageTrackWrapper>
        <ImageTrackWrapper>
          <ImageTrack
            images={[
              osr3,
              osr6,
              osr10,
              osr14,
              osr13,
              osr11,
              osr1,
              osr5,
              osr8,
              osr2,
              osr7,
              osr4,
            ]}
            speed={0.024}
            direction="left"
          />
        </ImageTrackWrapper>
        <ImageTrackWrapper>
          <ImageTrack
            images={[
              colorstone1,
              colorstone7,
              colorstone2,
              colorstone6,
              colorstone9,
              colorstone8,
              colorstone3,
              colorstone5,
              colorstone4,
            ]}
            speed={0.017}
            direction="left"
          />
        </ImageTrackWrapper>
      </SlidersContainer>
    </LookbookContainer>
  );
};

export default LookBook;
