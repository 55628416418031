import React from 'react';
import { Box } from '@mui/material';
import {
  SocialsContainer,
  SocialsHeading,
  SocialsSubHeading,
} from './SocialsStyles';
import SocialsButton from '../../components/SocialsButton/SocialsButton';

const Socials = () => {
  return (
    <SocialsContainer>
      <SocialsHeading>Paraline Socials</SocialsHeading>
      <SocialsSubHeading>Follow us - Never Fall in Line!</SocialsSubHeading>
      <Box margin={1}>
        <SocialsButton platform={'Instagram'} size={'large'} />
      </Box>
      <Box margin={1}>
        <SocialsButton platform={'TikTok'} size={'large'} />
      </Box>
      <Box margin={1}>
        <SocialsButton platform={'YouTube'} size={'large'} />
      </Box>
    </SocialsContainer>
  );
};

export default Socials;
