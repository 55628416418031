import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCart } from '../../CartContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import theme from '../../theme';
import Modal from 'react-modal';

import {
  Container,
  ProductInfo,
  ProductImage,
  ProductTitle,
  ProductDescription,
  ProductPrice,
  ColorFormControl,
  SizeFormControl,
  ImageSelector,
  ImageWrapper,
  StyledImage,
  SizeGuideText,
} from './SingleProductsStyles';
import MainButton from '../../components/MainButton/MainButton';

interface Size {
  size: string;
  stock: number;
}

interface Color {
  color: string;
  sizes: Size[];
}

interface Product {
  id: string;
  name: string;
  imageUrls: string[];
  description1: string;
  description2: string;
  description3: string;
  price: number;
  colors: Color[];
  sizeGuideUrl: string;
}

interface LocationState {
  product: Product;
}

interface ColorMapping {
  [key: string]: string;
}

const colorMapping: ColorMapping = {
  White: '#FFFFFF',
  Chrome: '#AAAAAA',
  Pink: '#FF98BF',
  Purple: '#D3BBED',
};

Modal.setAppElement('#root'); // This line is needed for accessibility reasons.

const SingleProduct: React.FC = () => {
  const location = useLocation();
  const { product } = location.state as LocationState;

  const initialColorObject = product.colors[0];
  const initialSelectedSize = initialColorObject?.sizes[0]
    ? initialColorObject.sizes.find((sizeObj: Size) => sizeObj.stock > 0)
        ?.size || ''
    : '';
  const initialAvailableSizes =
    initialColorObject?.sizes
      .filter((sizeObj: Size) => sizeObj.stock > 0)
      .map((sizeObj: Size) => sizeObj.size) || [];

  const [selectedColor, setSelectedColor] = useState(product.colors[0].color);
  const [selectedSize, setSelectedSize] = useState(initialSelectedSize);
  const [availableSizes, setAvailableSizes] = useState<string[]>(
    initialAvailableSizes
  );

  const getSelectedImages = () => {
    if (product.colors[0].color === selectedColor) {
      return product.imageUrls.slice(0, 4); // return first 4 images
    } else if (product.colors[1].color === selectedColor) {
      return product.imageUrls.slice(-4); // return last 4 images
    }
    return product.imageUrls; // default fallback
  };
  const [imageUrls, setImageUrls] = useState(getSelectedImages());
  const [currentImage, setCurrentImage] = useState(imageUrls[0]);

  // Determine if a size is available
  const isSizeAvailable = (size: string): boolean => {
    return availableSizes.includes(size);
  };

  useEffect(() => {
    const selectedColorObject = product.colors.find(
      (colorObject: Color) => colorObject.color === selectedColor
    );
    const newAvailableSizes =
      selectedColorObject?.sizes
        .filter((sizeObj: Size) => sizeObj.stock > 0)
        .map((sizeObj: Size) => sizeObj.size) || [];

    const newImageUrls = getSelectedImages();

    setImageUrls(newImageUrls);
    setCurrentImage(newImageUrls[0]);

    // Also store the sizes that have stock > 0
    setAvailableSizes(newAvailableSizes);
  }, [selectedColor, product]);

  const [modalIsOpen, setIsOpen] = useState(false);

  const areSizesAvailable = (): boolean => {
    return product.colors.some(color => color.sizes.length > 0);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const { cart, addToCart } = useCart();

  const handleAddToCart = () => {
    const selectedColorObject = product.colors.find(
      (colorObject: Color) => colorObject.color === selectedColor
    );
    const selectedSizeObject = selectedColorObject?.sizes.find(
      (sizeObject: Size) => sizeObject.size === selectedSize
    );
    const selectedStock = selectedSizeObject?.stock || 0;

    const itemInCart = cart.find(
      item =>
        item.id === product.id &&
        item.color === selectedColor &&
        item.size === selectedSize
    );

    const quantityInCart = itemInCart ? itemInCart.quantity : 0;

    if (selectedStock - quantityInCart > 0) {
      addToCart({
        id: product.id,
        name: product.name,
        description1: product.description1,
        description2: product.description2,
        description3: product.description3,
        price: product.price,
        quantity: 1,
        color: selectedColor,
        size: selectedSize,
        image:
          selectedColor === 'Pink'
            ? product.imageUrls[4]
            : product.imageUrls[0],
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      alert('Your selected size is sold out in this color');
    }
  };

  return (
    <Container>
      <ImageSelector
        sx={{
          order: {
            xs: 2,
            sm: 1,
          },
        }}
      >
        {imageUrls.map((imageUrl, index) => (
          <ImageWrapper
            key={index}
            style={{
              border:
                imageUrl === currentImage
                  ? `2px solid ${theme.palette.primary.main}`
                  : 'none',
            }}
          >
            <StyledImage
              src={imageUrl}
              alt={product.name}
              onClick={() => setCurrentImage(imageUrl)}
            />
          </ImageWrapper>
        ))}
      </ImageSelector>

      <ProductImage
        src={currentImage}
        alt={product.name}
        sx={{
          order: {
            xs: 1,
            sm: 2,
          },
        }}
      />

      <ProductInfo
        sx={{
          order: {
            xs: 3,
            sm: 3,
          },
        }}
      >
        <ProductTitle variant="h1">{product.name}</ProductTitle>
        <ProductPrice variant="h4">{product.price} €</ProductPrice>
        <ProductDescription variant="body1">
          {product.description1}
        </ProductDescription>
        <ProductDescription variant="body1">
          {product.description2}
        </ProductDescription>
        <ProductDescription variant="body1">
          {product.description3}
        </ProductDescription>

        {/*<SizeGuideText*/}
        {/*  variant="body1"*/}
        {/*  onClick={openModal}*/}
        {/*  style={{ cursor: 'pointer' }}*/}
        {/*>*/}
        {/*  Size Guide*/}
        {/*</SizeGuideText>*/}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            content: {
              position: 'relative',
              inset: 'auto',
              maxWidth: '90vw',
              maxHeight: '90vh',
            },
          }}
        >
          <img
            src={product.sizeGuideUrl}
            alt="Size Guide"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </Modal>

        <ColorFormControl as="fieldset">
          <RadioGroup
            row
            aria-label="color"
            name="color"
            value={selectedColor}
            onChange={e => setSelectedColor(e.target.value)}
          >
            {product.colors.map((colorObject: Color) => (
              <FormControlLabel
                key={colorObject.color}
                value={colorObject.color}
                control={
                  <Radio
                    sx={{
                      color: colorMapping[colorObject.color],
                      '&.Mui-checked': {
                        color: colorMapping[colorObject.color],
                      },
                    }}
                  />
                }
                label={colorObject.color}
                labelPlacement="bottom"
              />
            ))}
          </RadioGroup>
        </ColorFormControl>

        {areSizesAvailable() && (
          <SizeFormControl as="fieldset">
            <RadioGroup
              row
              aria-label="size"
              name="size"
              value={selectedSize}
              onChange={e => setSelectedSize(e.target.value)}
            >
              {product.colors[0].sizes.map((sizeObj: Size) => (
                <FormControlLabel
                  key={sizeObj.size}
                  value={sizeObj.size}
                  control={<Radio />}
                  label={sizeObj.size}
                  labelPlacement="bottom"
                  disabled={!isSizeAvailable(sizeObj.size)}
                />
              ))}
            </RadioGroup>
          </SizeFormControl>
        )}

        <MainButton disabled={!selectedSize} onClick={handleAddToCart}>
          Add to Cart
        </MainButton>
      </ProductInfo>
    </Container>
  );
};

export default SingleProduct;
