import React, { useState, useEffect, FormEvent } from 'react';
import {
  ButtonMargin,
  ContainerBox,
  ContainerButtons,
  ContainerText,
  MaintenanceText,
} from './MaintenancePageStyles';
import StarLogo from '../../components/Logos/StarLogo';
import SocialsButton from '../../components/SocialsButton/SocialsButton';
import MainButton from '../../components/MainButton/MainButton';

interface MaintenancePageProps {
  onGrantAccess: () => void;
}

const MaintenancePage: React.FC<MaintenancePageProps> = ({ onGrantAccess }) => {
  const [accessCode, setAccessCode] = useState('');
  const [accessCodeFieldVisible, setAccessCodeFieldVisible] = useState(false);
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const targetDate = new Date('2023-06-16T17:00:00+02:00');

    const updateCountdown = () => {
      const currentTime = new Date().getTime();
      const timeRemaining = targetDate.getTime() - currentTime;

      if (timeRemaining > 0) {
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);

        setCountdown(
          `${days.toString().padStart(2, '0')}:${hours
            .toString()
            .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`
        );
      } else {
        setCountdown('00:00:00');
      }
    };

    const countdownInterval = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!accessCodeFieldVisible) {
      setAccessCodeFieldVisible(true);
    } else {
      const earlyAccessCode = process.env.REACT_APP_EARLY_ACCESS_CODE;

      if (accessCode === earlyAccessCode) {
        onGrantAccess();
      } else {
        alert('Invalid access code.');
      }
    }
  };

  return (
    <ContainerBox>
      <StarLogo size={'extra_large'} color={'black'} />
      <ContainerText>
        <MaintenanceText variant="h4">Collection loading...</MaintenanceText>
        <MaintenanceText variant="h2">{countdown}</MaintenanceText>
      </ContainerText>
      <ContainerButtons>
        <SocialsButton platform={'Instagram'} size={'medium'} />
        <ButtonMargin />
        <SocialsButton platform={'TikTok'} size={'medium'} />
      </ContainerButtons>
      <ButtonMargin />
      <form onSubmit={handleSubmit}>
        {accessCodeFieldVisible && (
          <input
            type="password"
            value={accessCode}
            onChange={event => setAccessCode(event.currentTarget.value)}
            placeholder="Enter early access code"
          />
        )}
        <MainButton buttonVariant="secondary" type="submit">
          {accessCodeFieldVisible ? 'Submit' : 'Early Access'}
        </MainButton>
      </form>
    </ContainerBox>
  );
};

export default MaintenancePage;
