import React from 'react';
import { styled } from '@mui/system';
import { Link } from '@mui/material';
import MainButton from '../MainButton/MainButton';

interface SocialsButtonProps {
  platform: 'Instagram' | 'TikTok' | 'YouTube' | 'Website';
  size: 'small' | 'medium' | 'large';
}

// Here we extend MainButton with additional styling
const StyledMainButton = styled(MainButton)`
  min-width: 150px; // adjust the min width according to your need
`;

const SocialsButton: React.FC<SocialsButtonProps> = ({ platform, size }) => {
  let url;
  switch (platform) {
    case 'Instagram':
      url = 'https://www.instagram.com/paraline.de/';
      break;
    case 'TikTok':
      url = 'https://www.tiktok.com/@paraline.label';
      break;
    case 'YouTube':
      url = 'https://www.youtube.com/@paraline3780';
      break;
    case 'Website':
      url = 'https://www.paraline.de';
      break;
    default:
      url = '#';
      break;
  }

  return (
    <Link href={url} target="_blank" rel="noopener noreferrer" underline="none">
      <StyledMainButton size={size} buttonVariant="primary">
        {platform}
      </StyledMainButton>
    </Link>
  );
};

export default SocialsButton;
