import React from 'react';
import { Typography, Container } from '@mui/material';

const TermsAndConditions: React.FC = () => {
  return (
    <Container>
      <Typography variant="body1">
        <header>
          <h1>
            <strong>
              <span>AGB (Terms and Conditions)</span>
            </strong>
          </h1>
          <p></p>
        </header>
        <div>
          <h2>
            <span>1) Geltungsbereich</span>
          </h2>
          <p>
            <strong>
              <span>1.1</span>
            </strong>
            <span>
               Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") der
              Vinzenz Fitz und Kilian Ludwig GbR (nachfolgend "Verkäufer"),
              gelten für alle Verträge zur Lieferung von Waren, die ein
              Verbraucher oder Unternehmer (nachfolgend „Kunde“) mit dem
              Verkäufer hinsichtlich der vom Verkäufer in seinem Online-Shop
              dargestellten Waren abschließt. Hiermit wird der Einbeziehung von
              eigenen Bedingungen des Kunden widersprochen, es sei denn, es ist
              etwas anderes vereinbart.
            </span>
          </p>
          <p>
            <strong>
              <span>1.2</span>
            </strong>
            <span>
               Verbraucher im Sinne dieser AGB ist jede natürliche Person, die
              ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
              ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit
              zugerechnet werden können.
            </span>
          </p>
          <p>
            <strong>
              <span>1.3</span>
            </strong>
            <span>
               Unternehmer im Sinne dieser AGB ist eine natürliche oder
              juristische Person oder eine rechtsfähige Personengesellschaft,
              die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer
              gewerblichen oder selbständigen beruflichen Tätigkeit handelt.
            </span>
          </p>
          <h2>
            <span>2) Vertragsschluss</span>
          </h2>
          <p>
            <strong>
              <span>2.1</span>
            </strong>
            <span>
               Die im Online-Shop des Verkäufers enthaltenen
              Produktbeschreibungen stellen keine verbindlichen Angebote seitens
              des Verkäufers dar, sondern dienen zur Abgabe eines verbindlichen
              Angebots durch den Kunden.
            </span>
          </p>
          <p>
            <strong>
              <span>2.2</span>
            </strong>
            <span>
               Der Kunde kann das Angebot über das in den Online-Shop des
              Verkäufers integrierte Online-Bestellformular abgeben. Dabei gibt
              der Kunde, nachdem er die ausgewählten Waren in den virtuellen
              Warenkorb gelegt und den elektronischen Bestellprozess durchlaufen
              hat, durch Klicken des den Bestellvorgang abschließenden Buttons
              ein rechtlich verbindliches Vertragsangebot in Bezug auf die im
              Warenkorb enthaltenen Waren ab.
            </span>
          </p>
          <p>
            <strong>
              <span>2.3</span>
            </strong>
            <span>
               Der Verkäufer kann das Angebot des Kunden innerhalb von fünf
              Tagen annehmen,
            </span>
          </p>
          <ul>
            <li>
              <span>
                indem er dem Kunden eine schriftliche Auftragsbestätigung oder
                eine Auftragsbestätigung in Textform (Fax oder E-Mail)
                übermittelt, wobei insoweit der Zugang der Auftragsbestätigung
                beim Kunden maßgeblich ist, oder
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                indem er dem Kunden die bestellte Ware liefert, wobei insoweit
                der Zugang der Ware beim Kunden maßgeblich ist, oder
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                indem er den Kunden nach Abgabe von dessen Bestellung zur
                Zahlung auffordert.
              </span>
            </li>
          </ul>
          <p>
            <span>
              Liegen mehrere der vorgenannten Alternativen vor, kommt der
              Vertrag in dem Zeitpunkt zustande, in dem eine der vorgenannten
              Alternativen zuerst eintritt. Die Frist zur Annahme des Angebots
              beginnt am Tag nach der Absendung des Angebots durch den Kunden zu
              laufen und endet mit dem Ablauf des fünften Tages, welcher auf die
              Absendung des Angebots folgt. Nimmt der Verkäufer das Angebot des
              Kunden innerhalb vorgenannter Frist nicht an, so gilt dies als
              Ablehnung des Angebots mit der Folge, dass der Kunde nicht mehr an
              seine Willenserklärung gebunden ist.
            </span>
          </p>
          <p>
            <strong>
              <span>2.4</span>
            </strong>
            <span>
               Bei Auswahl einer von PayPal angebotenen Zahlungsart erfolgt die
              Zahlungsabwicklung über den Zahlungsdienstleister PayPal (Europe)
              S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxemburg
              (im Folgenden: „PayPal“), unter Geltung der
              PayPal-Nutzungsbedingungen, einsehbar unter 
            </span>
            <a href="https://www.paypal.com/de/webapps/mpp/ua/useragreement-full">
              <span>
                https://www.paypal.com/de/webapps/mpp/ua/useragreement-full
              </span>
            </a>
            <span>
               oder - falls der Kunde nicht über ein PayPal-Konto verfügt –
              unter Geltung der Bedingungen für Zahlungen ohne PayPal-Konto,
              einsehbar unter 
            </span>
            <a href="https://www.paypal.com/de/webapps/mpp/ua/privacywax-full">
              <span>
                https://www.paypal.com/de/webapps/mpp/ua/privacywax-full
              </span>
            </a>
            <span>
              . Zahlt der Kunde mittels einer im Online-Bestellvorgang
              auswählbaren von PayPal angebotenen Zahlungsart, erklärt der
              Verkäufer schon jetzt die Annahme des Angebots des Kunden in dem
              Zeitpunkt, in dem der Kunde den Button anklickt, welcher den
              Bestellvorgang abschließt.
            </span>
          </p>
          <p>
            <strong>
              <span>2.5</span>
            </strong>
            <span>
               Bei der Abgabe eines Angebots über das Online-Bestellformular des
              Verkäufers wird der Vertragstext nach dem Vertragsschluss vom
              Verkäufer gespeichert und dem Kunden nach Absendung von dessen
              Bestellung in Textform (z. B. E-Mail, Fax oder Brief) übermittelt.
              Eine darüber hinausgehende Zugänglichmachung des Vertragstextes
              durch den Verkäufer erfolgt nicht. Sofern der Kunde vor Absendung
              seiner Bestellung ein Nutzerkonto im Online-Shop des Verkäufers
              eingerichtet hat, werden die Bestelldaten auf der Website des
              Verkäufers archiviert und können vom Kunden über dessen
              passwortgeschütztes Nutzerkonto unter Angabe der entsprechenden
              Login-Daten kostenlos abgerufen werden.
            </span>
          </p>
          <p>
            <strong>
              <span>2.6</span>
            </strong>
            <span>
               Vor verbindlicher Abgabe der Bestellung über das
              Online-Bestellformular des Verkäufers kann der Kunde mögliche
              Eingabefehler durch aufmerksames Lesen der auf dem Bildschirm
              dargestellten Informationen erkennen. Ein wirksames technisches
              Mittel zur besseren Erkennung von Eingabefehlern kann dabei die
              Vergrößerungsfunktion des Browsers sein, mit deren Hilfe die
              Darstellung auf dem Bildschirm vergrößert wird. Seine Eingaben
              kann der Kunde im Rahmen des elektronischen Bestellprozesses so
              lange über die üblichen Tastatur- und Mausfunktionen korrigieren,
              bis er den Button anklickt, welcher den Bestellvorgang abschließt.
            </span>
          </p>
          <p>
            <strong>
              <span>2.7</span>
            </strong>
            <span>
               Für den Vertragsschluss steht ausschließlich die deutsche Sprache
              zur Verfügung.
            </span>
          </p>
          <p>
            <strong>
              <span>2.8</span>
            </strong>
            <span>
               Die Bestellabwicklung und Kontaktaufnahme finden in der Regel per
              E-Mail und automatisierter Bestellabwicklung statt. Der Kunde hat
              sicherzustellen, dass die von ihm zur Bestellabwicklung angegebene
              E-Mail-Adresse zutreffend ist, so dass unter dieser Adresse die
              vom Verkäufer versandten E-Mails empfangen werden können.
              Insbesondere hat der Kunde bei dem Einsatz von SPAM-Filtern
              sicherzustellen, dass alle vom Verkäufer oder von diesem mit der
              Bestellabwicklung beauftragten Dritten versandten E-Mails
              zugestellt werden können.
            </span>
          </p>
          <h2>
            <span>3) Widerrufsrecht</span>
          </h2>
          <p>
            <strong>
              <span>3.1</span>
            </strong>
            <span>
               Verbrauchern steht grundsätzlich ein Widerrufsrecht zu.
            </span>
          </p>
          <p>
            <strong>
              <span>3.2</span>
            </strong>
            <span>
               Nähere Informationen zum Widerrufsrecht ergeben sich aus der
              Widerrufsbelehrung des Verkäufers.
            </span>
          </p>
          <p>
            <strong>
              <span>3.3</span>
            </strong>
            <span>
               Das Widerrufsrecht gilt nicht für Verbraucher, die zum Zeitpunkt
              des Vertragsschlusses keinem Mitgliedstaat der Europäischen Union
              angehören und deren alleiniger Wohnsitz und Lieferadresse zum
              Zeitpunkt des Vertragsschlusses außerhalb der Europäischen Union
              liegen.
            </span>
          </p>
          <h2>
            <span>4) Preise und Zahlungsbedingungen</span>
          </h2>
          <p>
            <strong>
              <span>4.1</span>
            </strong>
            <span>
               Sofern sich aus der Produktbeschreibung des Verkäufers nichts
              anderes ergibt, handelt es sich bei den angegebenen Preisen um
              Gesamtpreise, die die gesetzliche Umsatzsteuer enthalten.
              Gegebenenfalls zusätzlich anfallende Liefer- und Versandkosten
              werden in der jeweiligen Produktbeschreibung gesondert angegeben.
            </span>
          </p>
          <p>
            <strong>
              <span>4.2</span>
            </strong>
            <span>
               Bei Lieferungen in Länder außerhalb der Europäischen Union können
              im Einzelfall weitere Kosten anfallen, die der Verkäufer nicht zu
              vertreten hat und die vom Kunden zu tragen sind. Hierzu zählen
              beispielsweise Kosten für die Geldübermittlung durch
              Kreditinstitute (z.B. Überweisungsgebühren, Wechselkursgebühren)
              oder einfuhrrechtliche Abgaben bzw. Steuern (z.B. Zölle). Solche
              Kosten können in Bezug auf die Geldübermittlung auch dann
              anfallen, wenn die Lieferung nicht in ein Land außerhalb der
              Europäischen Union erfolgt, der Kunde die Zahlung aber von einem
              Land außerhalb der Europäischen Union aus vornimmt.
            </span>
          </p>
          <p>
            <strong>
              <span>4.3</span>
            </strong>
            <span>
               Die Zahlungsmöglichkeit/en wird/werden dem Kunden im Online-Shop
              des Verkäufers mitgeteilt.
            </span>
          </p>
          <p>
            <strong>
              <span>4.4</span>
            </strong>
            <span>
               Bei Auswahl der Zahlungsart „SOFORT“ erfolgt die
              Zahlungsabwicklung über den Zahlungsdienstleister SOFORT GmbH,
              Theresienhöhe 12, 80339 München (im Folgenden „SOFORT“). Um den
              Rechnungsbetrag über „SOFORT“ bezahlen zu können, muss der Kunde
              über ein für die Teilnahme an „SOFORT“ frei geschaltetes
              Online-Banking-Konto verfügen, sich beim Zahlungsvorgang
              entsprechend legitimieren und die Zahlungsanweisung gegenüber
              „SOFORT“ bestätigen. Die Zahlungstransaktion wird unmittelbar
              danach von „SOFORT“ durchgeführt und das Bankkonto des Kunden
              belastet. Nähere Informationen zur Zahlungsart „SOFORT“ kann der
              Kunde im Internet unter 
            </span>
            <a href="https://www.klarna.com/sofort/">
              <span>https://www.klarna.com/sofort/</span>
            </a>
            <span> abrufen.</span>
          </p>
          <p>
            <strong>
              <span>4.5</span>
            </strong>
            <span>
               Bei Auswahl einer über den Zahlungsdienst "Klarna" angebotenen
              Zahlungsart erfolgt die Zahlungsabwicklung über die Klarna Bank AB
              (publ), Sveavägen 46, 111 34 Stockholm, Schweden (nachfolgend
              „Klarna“). Nähere Informationen sowie die Bedingungen von Klarna
              hierzu finden sich in den Zahlungsinformationen des Verkäufers,
              welche unter der folgenden Internetadresse einsehbar sind:
            </span>
          </p>
          <h2>
            <span>5) Liefer- und Versandbedingungen</span>
          </h2>
          <p>
            <strong>
              <span>5.1</span>
            </strong>
            <span>
               Bietet der Verkäufer den Versand der Ware an, so erfolgt die
              Lieferung innerhalb des vom Verkäufer angegebenen Liefergebietes
              an die vom Kunden angegebene Lieferanschrift, sofern nichts
              anderes vereinbart ist. Bei der Abwicklung der Transaktion ist die
              in der Bestellabwicklung des Verkäufers angegebene Lieferanschrift
              maßgeblich.
            </span>
          </p>
          <p>
            <strong>
              <span>5.2</span>
            </strong>
            <span>
               Scheitert die Zustellung der Ware aus Gründen, die der Kunde zu
              vertreten hat, trägt der Kunde die dem Verkäufer hierdurch
              entstehenden angemessenen Kosten. Dies gilt im Hinblick auf die
              Kosten für die Hinsendung nicht, wenn der Kunde sein
              Widerrufsrecht wirksam ausübt. Für die Rücksendekosten gilt bei
              wirksamer Ausübung des Widerrufsrechts durch den Kunden die in der
              Widerrufsbelehrung des Verkäufers hierzu getroffene Regelung.
            </span>
          </p>
          <p>
            <strong>
              <span>5.3</span>
            </strong>
            <span>
               Handelt der Kunde als Unternehmer, geht die Gefahr des zufälligen
              Untergangs und der zufälligen Verschlechterung der verkauften Ware
              auf den Kunden über, sobald der Verkäufer die Sache dem Spediteur,
              dem Frachtführer oder der sonst zur Ausführung der Versendung
              bestimmten Person oder Anstalt ausgeliefert hat. Handelt der Kunde
              als Verbraucher, geht die Gefahr des zufälligen Untergangs und der
              zufälligen Verschlechterung der verkauften Ware grundsätzlich erst
              mit Übergabe der Ware an den Kunden oder eine empfangsberechtigte
              Person über. Abweichend hiervon geht die Gefahr des zufälligen
              Untergangs und der zufälligen Verschlechterung der verkauften Ware
              auch bei Verbrauchern bereits auf den Kunden über, sobald der
              Verkäufer die Sache dem Spediteur, dem Frachtführer oder der sonst
              zur Ausführung der Versendung bestimmten Person oder Anstalt
              ausgeliefert hat, wenn der Kunde den Spediteur, den Frachtführer
              oder die sonst zur Ausführung der Versendung bestimmte Person oder
              Anstalt mit der Ausführung beauftragt und der Verkäufer dem Kunden
              diese Person oder Anstalt zuvor nicht benannt hat.
            </span>
          </p>
          <p>
            <strong>
              <span>5.4</span>
            </strong>
            <span>
               Der Verkäufer behält sich das Recht vor, im Falle nicht richtiger
              oder nicht ordnungsgemäßer Selbstbelieferung vom Vertrag
              zurückzutreten. Dies gilt nur für den Fall, dass die
              Nichtlieferung nicht vom Verkäufer zu vertreten ist und dieser mit
              der gebotenen Sorgfalt ein konkretes Deckungsgeschäft mit dem
              Zulieferer abgeschlossen hat. Der Verkäufer wird alle zumutbaren
              Anstrengungen unternehmen, um die Ware zu beschaffen. Im Falle der
              Nichtverfügbarkeit oder der nur teilweisen Verfügbarkeit der Ware
              wird der Kunde unverzüglich informiert und die Gegenleistung
              unverzüglich erstattet.
            </span>
          </p>
          <p>
            <strong>
              <span>5.5</span>
            </strong>
            <span>
               Selbstabholung ist aus logistischen Gründen nicht möglich.
            </span>
          </p>
          <h2>
            <span>6) Eigentumsvorbehalt</span>
          </h2>
          <p>
            <span>
              Tritt der Verkäufer in Vorleistung, behält er sich bis zur
              vollständigen Bezahlung des geschuldeten Kaufpreises das Eigentum
              an der gelieferten Ware vor.
            </span>
          </p>
          <h2>
            <span>7) Mängelhaftung (Gewährleistung)</span>
          </h2>
          <p>
            <strong>
              <span>7.1</span>
            </strong>
            <span>
               Soweit sich aus den nachfolgenden Regelungen nichts anderes
              ergibt, gelten die Vorschriften der gesetzlichen Mängelhaftung.
              Hiervon abweichend gilt bei Verträgen zur Lieferung von Waren:
            </span>
          </p>
          <p>
            <strong>
              <span>7.2</span>
            </strong>
            <span> Handelt der Kunde als Unternehmer,</span>
          </p>
          <ul>
            <li>
              <span>hat der Verkäufer die Wahl der Art der Nacherfüllung;</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                beträgt bei neuen Waren die Verjährungsfrist für Mängel ein Jahr
                ab Ablieferung der Ware;
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                sind bei gebrauchten Waren die Rechte und Ansprüche wegen
                Mängeln ausgeschlossen;
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                beginnt die Verjährung nicht erneut, wenn im Rahmen der
                Mängelhaftung eine Ersatzlieferung erfolgt.
              </span>
            </li>
          </ul>
          <p>
            <strong>
              <span>7.3</span>
            </strong>
            <span>
               Die vorstehend geregelten Haftungsbeschränkungen und
              Fristverkürzungen gelten nicht
            </span>
          </p>
          <ul>
            <li>
              <span>
                für Schadensersatz- und Aufwendungsersatzansprüche des Kunden,
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                für den Fall, dass der Verkäufer den Mangel arglistig
                verschwiegen hat,
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                für Waren, die entsprechend ihrer üblichen Verwendungsweise für
                ein Bauwerk verwendet worden sind und dessen Mangelhaftigkeit
                verursacht haben,
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                für eine ggf. bestehende Verpflichtung des Verkäufers zur
                Bereitstellung von Aktualisierungen für digitale Produkte, bei
                Verträgen zur Lieferung von Waren mit digitalen Elementen.
              </span>
            </li>
          </ul>
          <p>
            <strong>
              <span>7.4</span>
            </strong>
            <span>
               Darüber hinaus gilt für Unternehmer, dass die gesetzlichen
              Verjährungsfristen für einen ggf. bestehenden gesetzlichen
              Rückgriffsanspruch unberührt bleiben.
            </span>
          </p>
          <p>
            <strong>
              <span>7.5</span>
            </strong>
            <span>
               Handelt der Kunde als Kaufmann i.S.d. § 1 HGB, trifft ihn die
              kaufmännische Untersuchungs- und Rügepflicht gemäß § 377 HGB.
              Unterlässt der Kunde die dort geregelten Anzeigepflichten, gilt
              die Ware als genehmigt.
            </span>
          </p>
          <p>
            <strong>
              <span>7.6</span>
            </strong>
            <span>
               Handelt der Kunde als Verbraucher, so wird er gebeten,
              angelieferte Waren mit offensichtlichen Transportschäden bei dem
              Zusteller zu reklamieren und den Verkäufer hiervon in Kenntnis zu
              setzen. Kommt der Kunde dem nicht nach, hat dies keinerlei
              Auswirkungen auf seine gesetzlichen oder vertraglichen
              Mängelansprüche.
            </span>
          </p>
          <h2>
            <span>8) Anwendbares Recht</span>
          </h2>
          <p>
            <strong>
              <span>8.1</span>
            </strong>
            <span>
               Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der
              Bundesrepublik Deutschland unter Ausschluss der Gesetze über den
              internationalen Kauf beweglicher Waren. Bei Verbrauchern gilt
              diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch
              zwingende Bestimmungen des Rechts des Staates, in dem der
              Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.
            </span>
          </p>
          <p>
            <strong>
              <span>8.2</span>
            </strong>
            <span>
               Ferner gilt diese Rechtswahl im Hinblick auf das gesetzliche
              Widerrufsrecht nicht bei Verbrauchern, die zum Zeitpunkt des
              Vertragsschlusses keinem Mitgliedstaat der Europäischen Union
              angehören und deren alleiniger Wohnsitz und Lieferadresse zum
              Zeitpunkt des Vertragsschlusses außerhalb der Europäischen Union
              liegen.
            </span>
          </p>
          <h2>
            <span>9) Alternative Streitbeilegung</span>
          </h2>
          <p>
            <strong>
              <span>9.1</span>
            </strong>
            <span>
               Die EU-Kommission stellt im Internet unter folgendem Link eine
              Plattform zur Online-Streitbeilegung bereit: 
            </span>
            <a href="https://ec.europa.eu/consumers/odr">
              <span>https://ec.europa.eu/consumers/odr</span>
            </a>
          </p>
          <p>
            <span>
              Diese Plattform dient als Anlaufstelle zur außergerichtlichen
              Beilegung von Streitigkeiten aus Online-Kauf- oder
              Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist.
            </span>
          </p>
          <p>
            <strong>
              <span>9.2</span>
            </strong>
            <span>
               Der Verkäufer ist zur Teilnahme an einem
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              weder verpflichtet noch bereit.
            </span>
          </p>
        </div>
      </Typography>
    </Container>
  );
};

export default TermsAndConditions;
