import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import StepLabel from '@mui/material/StepLabel';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import {
  StyledAppBar,
  StyledButton,
  StyledMainContainer,
  StyledPaper,
  StyledStep,
  StyledStepper,
} from './CheckoutStyles';
import { Box } from '@mui/material';
import { useCart } from '../../CartContext';

import {
  doc,
  getDoc,
  updateDoc,
  getFirestore,
  runTransaction,
  collection,
  Timestamp,
} from 'firebase/firestore';

import { db } from '../../firebase/firebaseConfig';

// form values interfaces
interface AddressFormValues {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface PaymentFormValues {
  cardNumber: string;
  expiryDate: string;
  cvv: string;
}

// names of each step in the process
const steps = ['Shipping address', 'Payment details', 'Order information'];

export default function Checkout() {
  const { cart, clearCart } = useCart();

  const updateStockLevels = async () => {
    const db = getFirestore();

    for (let item of cart) {
      const productRef = doc(db, 'products', item.id);
      const productSnapshot = await getDoc(productRef);
      if (productSnapshot.exists()) {
        const productData = productSnapshot.data();

        // Find the color and size in the colors array
        const colorIndex = productData.colors.findIndex(
          (color: {
            color: string;
            sizes: { size: string; stock: number }[];
          }) => color.color === item.color
        );
        const sizeIndex = productData.colors[colorIndex].sizes.findIndex(
          (size: { size: string; stock: number }) => size.size === item.size
        );

        if (colorIndex !== -1 && sizeIndex !== -1) {
          // Update the stock quantity locally
          productData.colors[colorIndex].sizes[sizeIndex].stock -=
            item.quantity;

          // Update the product document in Firestore
          await updateDoc(productRef, { colors: productData.colors });
        }
      }
    }
  };

  // active step in the checkout process
  const [activeStep, setActiveStep] = useState(0);

  // form values for address and payment
  const [addressFormValues, setAddressFormValues] = useState<AddressFormValues>(
    {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    }
  );
  const [paymentFormValues, setPaymentFormValues] = useState<PaymentFormValues>(
    {
      cardNumber: '',
      expiryDate: '',
      cvv: '',
    }
  );

  // data to be reviewed before submission
  const [reviewData, setReviewData] = useState({
    addressFormValues,
    paymentFormValues,
    handlePayment: () => {},
  });

  // reference to promise returned by payment form
  const [paymentPromise, setPaymentPromise] = useState<
    (() => Promise<void>) | null
  >(() => Promise.resolve());

  // update review data whenever form values change
  useEffect(() => {
    setReviewData({
      addressFormValues,
      paymentFormValues,
      handlePayment: () => Promise.resolve(),
    });
  }, [addressFormValues, paymentFormValues]);

  // form submit function references
  const addressFormSubmitRef = useRef<(() => void) | null>(null);
  const paymentFormSubmitRef = useRef<(() => void) | null>(null);

  // validation states for each form
  const [addressFormIsValid, setAddressFormIsValid] = useState(false);
  const [paymentFormIsValid, setPaymentFormIsValid] = useState(false);

  // states for loading and order submission status
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);

  // error state for payment processing
  const [paymentError, setPaymentError] = useState<string | null>(null);

  // handles advancing to the next step in the process
  const handleNext = (data?: any) => {
    if (activeStep === 0) {
      if (addressFormIsValid) {
        addressFormSubmitRef.current?.();
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        alert(
          'Please fill in all required fields (*) in the address form and make sure to provide a valid email address.'
        );
      }
    } else if (activeStep === 1) {
      if (paymentFormIsValid) {
        paymentFormSubmitRef.current?.();
        console.log('PaymentForm submitted.'); // Add this log

        if (typeof data?.handlePayment === 'function') {
          setPaymentPromise(() => data.handlePayment);
          console.log('Payment promise set.'); // Add this log
        } else {
          setPaymentPromise(() => Promise.resolve());
        }
        setReviewData({
          addressFormValues,
          paymentFormValues,
          handlePayment:
            typeof data?.handlePayment === 'function'
              ? data.handlePayment
              : () => Promise.resolve(),
        });
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        // alert('Please fill in all required fields (*) in the payment form');
        alert('We currently only accept PayPal payments.');
      }
    }
  };

  // handles returning to the previous step in the process
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // handles button click based on the active step
  const handleButtonClick = () => {
    if (activeStep === steps.length - 1) {
      setIsLoading(true);

      if (typeof paymentPromise === 'function') {
        console.log('Executing payment promise...'); // Add this log

        paymentPromise()
          .then(() => {
            console.log(reviewData);
            setIsOrderPlaced(true);
          })
          .catch((error: any) => {
            if (error instanceof Error) {
              console.error(error);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      handleNext();
    }
  };

  // handles changes in payment status
  const handlePaymentStatusChange = (status: string) => {
    if (status === 'succeeded') {
      updateStockLevels().then(() => {
        const ordersRef = collection(db, 'orders');
        const counterRef = doc(db, 'counters', 'orderNumber');

        runTransaction(db, async transaction => {
          const counterDoc = await transaction.get(counterRef);

          if (!counterDoc.exists()) {
            throw 'Counter document does not exist!';
          }

          let newCount = counterDoc.data().count + 1;
          transaction.update(counterRef, { count: newCount });

          const orderNumber = `#2306${newCount}`;
          console.log(orderNumber);

          const orderData = {
            orderNumber: orderNumber,
            address: addressFormValues,
            items: cart, // Items from the CartContext
            createdAt: Timestamp.now(),
          };

          await transaction.set(doc(ordersRef, orderNumber), orderData);

          setIsOrderPlaced(true);
          setPaymentError(null);
          clearCart();
        }).catch(error => {
          console.error('Error adding document: ', error);
        });
      });
    } else if (status === 'failed') {
      setIsOrderPlaced(false);
      setPaymentError('The payment failed. Please try again.');
    }
  };

  // main component render
  return (
    <React.Fragment>
      <CssBaseline />
      <StyledAppBar position="absolute"></StyledAppBar>
      <StyledMainContainer maxWidth="sm">
        <StyledPaper variant="outlined">
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <StyledStepper activeStep={activeStep}>
            {steps.map(label => (
              <StyledStep key={label}>
                <StepLabel>{label}</StepLabel>
              </StyledStep>
            ))}
          </StyledStepper>
          {isLoading ? (
            <Typography variant="h5" gutterBottom>
              Processing your order...
            </Typography>
          ) : isOrderPlaced ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Your order was successful - Thank you!
              </Typography>
              <Typography variant="subtitle1">
                We will send your order confirmation and invoice in the
                following days, as soon as we processed your order. If you have
                any further questions or feedback, feel free to reach out to us:
                info@paraline.de
              </Typography>
            </React.Fragment>
          ) : paymentError ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Payment Error
              </Typography>
              <Typography variant="subtitle1">{paymentError}</Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AddressForm
                formValues={addressFormValues}
                updateFormValues={setAddressFormValues}
                submitRef={addressFormSubmitRef}
                hidden={activeStep !== 0}
                setAddressFormIsValid={setAddressFormIsValid}
              />
              <PaymentForm
                formValues={paymentFormValues}
                setFormValues={setPaymentFormValues}
                hidden={activeStep !== 1}
                setFormIsValid={setPaymentFormIsValid}
                submitRef={paymentFormSubmitRef}
                onPaymentStatusChange={handlePaymentStatusChange}
                setPaymentError={setPaymentError}
              />
              {activeStep === 2 && <Review hidden={activeStep !== 2} />}
              {activeStep !== steps.length - 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <StyledButton onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </StyledButton>
                  )}
                  <StyledButton
                    variant="contained"
                    onClick={handleButtonClick}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === 1 ? 'Place order' : 'Next'}
                  </StyledButton>
                </Box>
              )}
            </React.Fragment>
          )}
        </StyledPaper>
      </StyledMainContainer>
    </React.Fragment>
  );
}
