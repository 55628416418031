import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#724694',
    },
    secondary: {
      main: '#B5A9C5',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    background: {
      default: '#f5f5f5',
      shade: '#666',
      paper: '#fff',
      dark: '#222222',
    },
    text: {
      primary: '#333',
      secondary: '#666',
      light: '#f5f5f5',
      light_secondary: '#999',
    },
  },
  typography: {
    fontFamily: 'Teko, Arial', // Arial as fallback
    h1: {
      fontWeight: 500,
      fontSize: '2.5rem',
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.75rem',
      lineHeight: 1.3,
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.4,
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
  },
  // multiplier
  spacing: 8,

  // Add shape key
  shape: {
    borderRadius: 4, // 4px border radius, you can adjust this as needed
  },
});

export default theme;
