import { collection, getDocs } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { db } from './firebaseConfig';
import { storage } from './firebaseConfig';

const fetchProductInfo = async (collectionName: string) => {
  const productCollection = collection(db, collectionName);
  const productSnapshot = await getDocs(productCollection);
  const products = await Promise.all(
    productSnapshot.docs.map(async doc => {
      const data = doc.data();

      // Fetch image URLs for each product if pictures is defined
      const imageUrls = data.pictures
        ? await Promise.all(
            data.pictures.map(async (imagePath: string) => {
              const imageRef = ref(storage, imagePath);
              return await getDownloadURL(imageRef);
            })
          )
        : [];

      return {
        id: doc.id,
        ...data,
        imageUrls,
      };
    })
  );
  return products;
};

export default fetchProductInfo;
