// src/pages/CartPage.tsx

import React from 'react';
import { useCart } from '../../CartContext';
import { useNavigate } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  CartContainer,
  CartItem,
  ItemTitle,
  ItemPrice,
  ItemQuantity,
  ItemImage,
  TotalPrice,
  PageTitle,
  ItemColor,
  ItemSize,
  TextContainer,
} from './CartPageStyles';
import MainButton from '../../components/MainButton/MainButton';
import { IconButton } from '@mui/material';

export const CartPage: React.FC = () => {
  const { cart, removeFromCart } = useCart();
  const navigate = useNavigate();

  const totalPrice = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const handleCheckout = () => {
    navigate('/checkout');
  };

  const handleShopNow = () => {
    navigate('/space-collection');
  };

  if (cart.length === 0) {
    return (
      <CartContainer>
        <PageTitle>Your Cart is Empty</PageTitle>
        <MainButton
          buttonVariant="primary"
          size={'large'}
          onClick={handleShopNow}
        >
          Shop Now
        </MainButton>
      </CartContainer>
    );
  }

  return (
    <CartContainer>
      <PageTitle>Your Cart</PageTitle>
      {cart.map(item => (
        <CartItem key={item.id}>
          <ItemImage src={item.image} alt={item.name} />
          <TextContainer>
            <ItemTitle>{item.name}</ItemTitle>
            <ItemColor>Color: {item.color}</ItemColor>
            <ItemSize>Size: {item.size}</ItemSize>
            <ItemQuantity>Quantity: {item.quantity}</ItemQuantity>
            <ItemPrice>{item.price}€</ItemPrice>
          </TextContainer>

          <IconButton
            onClick={() => removeFromCart(item.id)}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                transform: 'scale(1.1)',
              },
            }}
          >
            <HighlightOffIcon sx={{ color: '#f5f5f5' }} />
          </IconButton>
        </CartItem>
      ))}
      <TotalPrice>Total: {totalPrice}€</TotalPrice>
      <MainButton
        buttonVariant="primary"
        size={'large'}
        onClick={handleCheckout}
      >
        Go to Checkout
      </MainButton>
    </CartContainer>
  );
};
