import React from 'react';
import { LogoImage } from './TextLogoStyles';
import TextLogoBlack from '../../assets/logo/TextLogoBlack.png';
import TextLogoWhite from '../../assets/logo/TextLogoWhite.png';

interface SecondLogoProps {
  size: 'small' | 'medium' | 'large' | 'extra_large';
  color: 'black' | 'white';
}

const TextLogo: React.FC<SecondLogoProps> = ({ size, color }) => {
  let logoSize;
  switch (size) {
    case 'small':
      logoSize = '50px';
      break;
    case 'medium':
      logoSize = '100px';
      break;
    case 'large':
      logoSize = '150px';
      break;
    case 'extra_large':
      logoSize = '200px';
      break;
    default:
      logoSize = '100px';
      break;
  }

  let logoImage;
  switch (color) {
    case 'black':
      logoImage = TextLogoBlack;
      break;
    case 'white':
      logoImage = TextLogoWhite;
      break;
    default:
      logoImage = TextLogoBlack;
      break;
  }

  return (
    <LogoImage src={logoImage} alt="Text Logo" style={{ width: logoSize }} />
  );
};

export default TextLogo;
