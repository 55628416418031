import { styled } from '@mui/system';

export const LookbookContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  minHeight: '100vh',
  backgroundColor: 'black',
}));

export const LookbookHeading = styled('h1')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: theme.palette.common.white,
}));

export const SlidersContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  maxWidth: '2400px',
  margin: 'auto',
}));

export const ImageTrackWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '56vmin',
  overflow: 'hidden',
  marginBottom: theme.spacing(2),
}));
