import * as React from 'react';
import Typography from '@mui/material/Typography';

interface ReviewProps {
  hidden: boolean;
}

export default function Review({ hidden }: ReviewProps) {
  return hidden ? null : (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Processing your order...
      </Typography>
    </React.Fragment>
  );
}

// import * as React from 'react';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import ListItemText from '@mui/material/ListItemText';
// import {
//   ReviewList,
//   ReviewListItem,
//   ReviewTotal,
//   ReviewGridContainer,
// } from './ReviewStyles';
// import { useCart } from '../../CartContext';
//
// interface AddressFormValues {
//   firstName: string;
//   lastName: string;
//   address1: string;
//   address2: string;
//   city: string;
//   state: string;
//   zip: string;
//   country: string;
// }
//
// interface PaymentFormValues {
//   cardNumber: string;
//   expiryDate: string;
//   cvv: string;
// }
//
// interface ReviewProps {
//   data: {
//     addressFormValues: AddressFormValues;
//     paymentFormValues: PaymentFormValues;
//     hidden: boolean;
//   };
// }
//
// export default function Review({ data }: ReviewProps) {
//   const { addressFormValues, paymentFormValues } = data;
//   const addresses = [
//     addressFormValues.address1,
//     addressFormValues.address2,
//     addressFormValues.city,
//     addressFormValues.state,
//     addressFormValues.zip,
//     addressFormValues.country,
//   ];
//
//   const payments = [
//     { name: 'Card number', detail: paymentFormValues.cardNumber },
//     { name: 'Expiry date', detail: paymentFormValues.expiryDate },
//     { name: 'CVV', detail: '***' },
//   ];
//
//   const { cart } = useCart();
//   const totalPrice = cart.reduce(
//     (acc, item) => acc + item.price * item.quantity,
//     0
//   );
//
//   return data.hidden ? null : (
//     <React.Fragment>
//       <Typography variant="h6" gutterBottom>
//         Order summary
//       </Typography>
//       <ReviewList disablePadding>
//         {cart.map((product, index) => (
//           <ReviewListItem key={index}>
//             <ListItemText primary={product.name} secondary={product.quantity} />
//             <Typography variant="body2">{product.price}€</Typography>
//           </ReviewListItem>
//         ))}
//         <ReviewListItem>
//           <ListItemText primary="Total" />
//           <ReviewTotal variant="subtitle1">{totalPrice}€</ReviewTotal>
//         </ReviewListItem>
//       </ReviewList>
//       <ReviewGridContainer container spacing={2}>
//         <Grid item xs={12} sm={6}>
//           <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//             Shipping
//           </Typography>
//           <Typography gutterBottom>
//             {addressFormValues.firstName} {addressFormValues.lastName}
//           </Typography>
//           <Typography gutterBottom>{addresses.join(', ')}</Typography>
//         </Grid>
//         <Grid item container direction="column" xs={12} sm={6}>
//           <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//             Payment details
//           </Typography>
//           <Grid container>
//             {payments.map(payment => (
//               <React.Fragment key={payment.name}>
//                 <Grid item xs={6}>
//                   <Typography gutterBottom>{payment.name}</Typography>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography gutterBottom>{payment.detail}</Typography>
//                 </Grid>
//               </React.Fragment>
//             ))}
//           </Grid>
//         </Grid>
//       </ReviewGridContainer>
//     </React.Fragment>
//   );
// }
